const { isArray, isFunction } = require('underscore');
const screens = require('../screens');
const views = require('./views');
const processScreen = require('./processScreen');

const BAD_SCREEN = 'screen-bad';


/**
 * Given an index and a parent container, this function will lookup
 * and return an instance of the appropriate screen.
 *
 * @param  {Number}     index   the screen index
 * @param  {DOMElement} $parent a jQuery enhanced DOM element to use
 * as the parent for this element
 * @param  {Backbone.Router} router the current router instance
 * @param  {Object} store a reference to the data store
 * @return {Backbone.View}         a Backbone.View instance for the
 * current screen
 */
module.exports = (index, $parent, router, store) => {
  let conf, language, show;
  
  conf = screens[index];
  language = store.meta.get('language');
  
  // if it is a function, use it to resolve
  // itself... you did a good job of making
  // this confusing, joe...
  if (isFunction(conf)) {
    conf = conf(store);
  }
  
  if (conf && views[conf.view]) {
    return new views[conf.view](
      processScreen(
        conf,
        language,
        index,
        $parent,
        router,
        store
      )
    );
  }
  
  return new views[BAD_SCREEN]({
    $parent: $parent,
    templateOptions: {
      title: `MISSING -- ${conf.screen} (${conf.view})`
    }
  });
}
