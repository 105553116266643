var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (body, helpers, option, responses, section, title) {pug_mixins["checkbox"] = pug_interp = function(num, row, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
let meChecked = helpers.isChecked(num, responses['answer']);
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["checkbox",`check${row}`,(meChecked ? 'checked' :'')], [false,true,true]), false, true)+pug.attr("for", `checkbox${num}`, true, true)) + "\u003E\u003Cdiv class=\"background\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"singlelinetexther anima-valign-text-middle\"\u003E" + (null == (pug_interp = label) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"rectanglecopy9 checktangle\"\u003E\u003Cdiv class=\"rectangleChecked\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (pug.attr("id", `checkbox${num}`, true, true)+" type=\"checkbox\" name=\"answer\""+pug.attr("data-string", label, true, true)+pug.attr("value", num, true, true)+pug.attr("checked", meChecked, true, true)) + "\u003E\u003C\u002Flabel\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"screenTextOverCheckboxes\"\u003E\u003Cdiv class=\"title\"\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"section\"\u003E" + (null == (pug_interp = section) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"body anima-valign-text-middle\"\u003E" + (null == (pug_interp = body) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"checkboxlist\"\u003E";
pug_mixins["checkbox"](0, 1, option[0]);
pug_mixins["checkbox"](1, 2, option[1]);
pug_mixins["checkbox"](2, 3, option[2]);
pug_mixins["checkbox"](3, 4, option[3]);
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["checkboxandinput","checkbox",(responses.helpBox ? 'checked' : '')], [false,false,true]), false, true)+" for=\"helpBox\"") + "\u003E\u003Cdiv class=\"rectanglecopy9 checktangle\"\u003E\u003Cdiv class=\"rectangleChecked\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (" class=\"checkInput\""+" id=\"helpBox\" type=\"checkbox\" name=\"helpBox\""+pug.attr("checked", responses.helpBox, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003Cdiv class=\"checkboxandinput textbox\"\u003E\u003Cimg class=\"rectanglecopy\" src=\"\u002Fimg\u002Ftext-over-checkboxes-rectangle-copy.png\"\u003E\u003Cinput" + (" class=\"textInput\""+" type=\"text\" name=\"helpText\""+pug.attr("placeholder", locals['hint-text'], true, true)+pug.attr("value", responses.helpText, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"body" in locals_for_with?locals_for_with.body:typeof body!=="undefined"?body:undefined,"helpers" in locals_for_with?locals_for_with.helpers:typeof helpers!=="undefined"?helpers:undefined,"option" in locals_for_with?locals_for_with.option:typeof option!=="undefined"?option:undefined,"responses" in locals_for_with?locals_for_with.responses:typeof responses!=="undefined"?responses:undefined,"section" in locals_for_with?locals_for_with.section:typeof section!=="undefined"?section:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;