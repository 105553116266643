module.exports = [
  require('./configs/A0.js'),
  require('./configs/A1.js'),
  require('./configs/A2.js'),
  require('./configs/A3.js'),
  require('./configs/A4.js'),
  require('./configs/A5.js'),
  require('./configs/A6.js'),
  require('./configs/A7.js'),
  require('./configs/A8.js'),
  // require('./configs/A9.js'),
  require('./configs/B1.js'),
  require('./configs/B2.js'),
  require('./configs/B3.js'),
  // require('./configs/B4.js'),
  // require('./configs/B5.js'),
  // require('./configs/B6.js'),
  // require('./configs/B7.js'),
  // require('./configs/B8.js'),
  // require('./configs/B9.js'),
  require('./configs/B10.js'),
  require('./configs/B11.js'),
  // require('./configs/B12.js'),
  // require('./configs/B13.js'),
  // require('./configs/B14.js'),
  // require('./configs/B15.js'),
  require('./configs/B16.js'),
  require('./configs/B17.js'),
  // require('./configs/B18.js'),
  require('./configs/B24.js'),
  require('./configs/B25.js'),
  require('./configs/B26.js'),
  require('./configs/B27.js'),
  require('./configs/B28.js'),
  require('./configs/C1.js'),
  require('./configs/C2.js'),
  require('./configs/C3-C4.js'),
  require('./configs/C5.js'),
  // require('./configs/C11.js'),
  require('./configs/C12.js'),
  require('./configs/C13.js'),
  require('./configs/C14.js'),
  require('./configs/C15.js'),
  require('./configs/D1.js'),
  require('./configs/D2.js'),
  require('./configs/D3.js'),
  require('./configs/D4.js'),
  require('./configs/D5.js'),
  require('./configs/D6.js'),
  require('./configs/D7.js'),
  require('./configs/D8-1.js'),
  require('./configs/D8-2.js'),
  require('./configs/D8-3.js'),
  require('./configs/D8-4.js'),
  require('./configs/D9.js'),
  require('./configs/D10.js'),
  require('./configs/D11.js'),
  require('./configs/E1.js'),
  require('./configs/E2.js'),
  require('./configs/E3.js'),
  require('./configs/E4-E5-E6.js'),
  require('./configs/E7.js'),
  require('./configs/E8-notes.js'),
  require('./configs/E8.js')
];
