const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-full-text.css');

const LARC_METHODS = [0, 2, 3];

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenFullText.pug'),
  screenType: 'screen-full-text',
  postRender () {
    this.systemEvents.on({
      'link:larc': this.openLARC
    }, this);
  },
  openLARC () {
    this.modals[0].render(LARC_METHODS, true);
    this.modals[0].show();
  },
  unload () {
    this.destroyModals();
    this.systemEvents.off(null, null, this);
  }
});
