module.exports = {
  screen: 'D3-others',
  section: 'D',
  view: 'screen-add-other-reasons',
  screenKeys: [
    'title.1',
    'subtitle.2',
    'placeholder.3',
    'placeholder.4',
    'placeholder.5',
    'button.6'
  ]
};
