module.exports = {
  screen: 'C14',
  section: 'C',
  view: 'screen-radio',
  screenKeys: [
    'title.1',
    'question.2',
    'option.3',
    'feedback.4',
    'option.5',
    'feedback.6',
    'option.7',
    'feedback.8',
    'footnote.9',
  ],
  modals: [
    require('./C11')
  ]
};
