const $ = require('jquery');
const { isFunction } = require('underscore');
const ScreenBaseModal = require('./ScreenBaseModal');

require('@assets/css/screen-tabbed-modal.css');

module.exports = ScreenBaseModal.extend({
  template: require('@templates/ScreenTabbedModal.pug'),
  screenType: 'screen-tabbed-modal',
  events: {
    'click .js-close': 'closeModal',
    'click .js-switchTab': 'switchTab'
  },
  initialize (opts) {
    ScreenBaseModal.prototype.initialize.apply(
      this,
      arguments
    );
    
    // listen for tab switch events
    this.systemEvents.on({
      'link:tabbed': this.goToTab
    }, this);
  },
  preShow (currentTab) {
    this.goToTab(currentTab);
  },
  closeModal () {
    this.systemEvents.trigger('popover:hide');
    this.hide();
  },
  switchTab (ev) {
    let $el = $(ev.currentTarget);
    let tab = $el.data('tab');

    this.systemEvents.trigger('popover:hide');

    this.goToTab(tab);
  },
  goToTab (tab) {
    this.$('.menuItem').removeClass('selected');
    this.$(`.menuItem-${tab}`).addClass('selected');

    this.$('.tab').addClass('hidden');
    this.$('.js-modalContent').scrollTop(0);
    this.$(`.tab-${tab}`).removeClass('hidden');
  },
  destroy () {
    if (isFunction(ScreenBaseModal.prototype.destroy)) {
      ScreenBaseModal.prototype.destroy.apply(
        this,
        arguments
      );
    }
    
    this.systemEvents.off('link:tabbed', null, this);
  }
});
