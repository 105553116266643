var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (comparisons, footnote, label) {pug_mixins["question"] = pug_interp = function(q){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Ctd\u003E\u003Cstrong\u003E" + (null == (pug_interp = q) ? "" : pug_interp) + "\u003C\u002Fstrong\u003E\u003C\u002Ftd\u003E";
};
pug_mixins["answer"] = pug_interp = function(a){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Ctd\u003E\u003Cdiv class=\"outer\"\u003E\u003Cdiv class=\"inner\"\u003E" + (null == (pug_interp = a) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"screenComparisonTable\"\u003E\u003Cdiv class=\"screenComparisonTable-wrap js-modalContent\"\u003E\u003Ctable class=\"screenComparisonTable-table\"\u003E\u003Cthead\u003E\u003Ctr\u003E\u003Cth\u003E\u003C\u002Fth\u003E";
// iterate comparisons
;(function(){
  var $$obj = comparisons;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var method = $$obj[pug_index0];
pug_html = pug_html + "\u003Cth\u003E\u003Cdiv class=\"outer\"\u003E\u003Cdiv class=\"inner\"\u003E" + (null == (pug_interp = method.type) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fth\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var method = $$obj[pug_index0];
pug_html = pug_html + "\u003Cth\u003E\u003Cdiv class=\"outer\"\u003E\u003Cdiv class=\"inner\"\u003E" + (null == (pug_interp = method.type) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fth\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E\u003C\u002Fthead\u003E\u003Ctbody\u003E";
// iterate label
;(function(){
  var $$obj = label;
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var lbl = $$obj[i];
pug_html = pug_html + "\u003Ctr\u003E";
pug_mixins["question"](lbl);
// iterate comparisons
;(function(){
  var $$obj = comparisons;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var method = $$obj[pug_index2];
pug_mixins["answer"](method.rows[i]);
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var method = $$obj[pug_index2];
pug_mixins["answer"](method.rows[i]);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var lbl = $$obj[i];
pug_html = pug_html + "\u003Ctr\u003E";
pug_mixins["question"](lbl);
// iterate comparisons
;(function(){
  var $$obj = comparisons;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var method = $$obj[pug_index3];
pug_mixins["answer"](method.rows[i]);
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var method = $$obj[pug_index3];
pug_mixins["answer"](method.rows[i]);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003Cp class=\"help\"\u003E" + (null == (pug_interp = footnote) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003Cbutton class=\"close js-closeModal\"\u003E\u003Cdiv class=\"group\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"comparisons" in locals_for_with?locals_for_with.comparisons:typeof comparisons!=="undefined"?comparisons:undefined,"footnote" in locals_for_with?locals_for_with.footnote:typeof footnote!=="undefined"?footnote:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined));;return pug_html;};
module.exports = template;