module.exports = {
  screen: 'D9',
  section: 'D',
  view: 'screen-two-column-input',
  screenKeys: [
    'title.1',
    'column.2',
    'column.3',
    'hint-text.4'
  ]
};
