const $ = require('jquery');
const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-two-column-input.css');

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenTwoColumnInput.pug'),
  screenType: 'screen-two-columns-input',
  events: {
    'change textarea': 'recordNotes'
  },
  recordNotes (ev) {
    let $el = $(ev.currentTarget);
    let value = $el.val();
    let name = $el.attr('name');
    
    this.store.responses.set(
      this.screenName,
      name,
      value
    );
  }
});
