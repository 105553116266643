const $ = require('jquery');
const ScreenBaseCheckbox = require('./ScreenBaseCheckbox');
const PopoverMethod = require('./PopoverMethod');

const $body = $('body');

require('@assets/css/screen-checkbox-list.css');

module.exports = ScreenBaseCheckbox.extend({
  template: require('@templates/ScreenCheckboxList.pug'),
  screenType: 'screen-checkbox-list',
  events: {
    'change :checkbox': 'addChecked',
    'keyup #anotherMethodInput': 'recordAnother',
    'click .js-openMethodPopover': 'openMethodPopover'
  },
  postRender () {
    this.$methodPopover = new PopoverMethod({
      $parent: $body
    });
        
    this.systemEvents.once({
      'screen:change': this.closeStuff
    }, this);
  },
  openMethodPopover (ev) {
    let $el = $(ev.currentTarget);
    let methodIdx = $el.data('method');
    let column = $el.data('column');
    
    ev.preventDefault();
    ev.stopPropagation();
    
    this.$methodPopover.render(
      methodIdx
    );
  },
  closeStuff () {
    this.$methodPopover.hide();
  },
  recordAnother () {
    this.store.responses.set(
      this.screenName,
      'anotherMethodText',
      this.$('#anotherMethodInput').val()
    );
  },
  addChecked (ev) {
    let $input, isChecked, name;
    
    ScreenBaseCheckbox.prototype.addChecked.apply(this, arguments);
    
    $input = $(ev.currentTarget);
    isChecked = $input.is(':checked');
    name = $input.attr('name');
    
    if (name === 'anotherMethod') {
      let $anotherMethodText = this.$('#anotherMethodInput');
      
      if (isChecked) {
        this.store.responses.set(
          this.screenName,
          'anotherMethodText',
          $anotherMethodText.val()
        );
        
        $anotherMethodText.focus();
      } else {
        this.store.responses.set(
          this.screenName,
          'anotherMethodText',
          false
        );
      }
    } else if (name === 'notInterested') {
      let $notInterestedText = this.$('#notInterestedText');
      
      if (isChecked) {
        this.store.responses.set(
          this.screenName,
          'notInterestedText',
          $notInterestedText.text()
        );
      } else {
        this.store.responses.set(
          this.screenName,
          'notInterestedText',
          false
        );
      }
    }
  }
});
