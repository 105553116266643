const YES = 0;

// FIXME: questions + options don't match
module.exports = {
  screen: 'D8-3',
  translationKey: 'D8',
  section: 'D',
  view: 'screen-multiple-radio',
  conditionalNext: true,
  screenKeys: [
    'question.1',
    'options.2',
    'hint-text.3',
    'question.4',
    'options.5',
    'question.6',
    'options.7',
    'question.8',
    'options.9',
    'question.10',
    'options.11',
    'question.12',
    'hint-text.13',
    'checkbox.14',
    'hideAnother',
    'fillOutDefault'
  ],
  preConditions: {
    'hideAnother': function (store) {
      let { doAnother } = store.responses.get('D8-3');
      
      return doAnother == true;
    },
    'fillOutDefault': function (store) {
      return YES;
    }
  },
  conditionalShow (store) {
    let { doAnother } = store.responses.get('D8-2');
    
    return doAnother == true;
  }
};
