const { isArray } = require('underscore');

const YES = 0;

module.exports = {
  screen: 'D4',
  section: 'D',
  view: 'screen-drag-drop',
  screenKeys: [
    'title.1',
    'body.2',
    'section.3',
    'section.4'
  ],
  dynamicContent (store) {
    let { reason } = store.responses.get('D3');
    let others = store.responses.get('D3-others');
    let extras = [
      others.reason1,
      others.reason2,
      others.reason3
    ].filter(r => (r || '').trim().length);
    
    
    return {
      reasons: [].concat(
        reason,
        extras
      ).filter(r => r)
    };
  },
  conditionalShow (store) {
    let { answer } = store.responses.get('D2');
    let { reason } = store.responses.get('D3');
    let others = store.responses.get('D3-others');
    let extras = [
      others.reason1,
      others.reason2,
      others.reason3
    ].filter(r => (r || '').trim().length);
    let combined = (
      (reason || []).length +
      (extras || []).length
    );
    
    if (combined === 1) {
      if ((reason || []).length){
        store.responses.set('D4', [
          reason[0]
        ]);
      } else {
        store.responses.set('D4', [
          extras[0]
        ]);
      }
    }
    
    return (
      (answer == YES) &&
      combined > 1
    );
  }
};
