module.exports = {
  screen: 'B17',
  section: 'B',
  view: 'screen-three-column',
  screenKeys: [
    'title.1',
    'column-title.2',
    'column-subtitle.3',
    'column-body.4',
    'column-button.5',
    'column-title.6',
    'column-subtitle.7',
    'column-body.8',
    'column-button.9',
    'column-title.10',
    'column-subtitle.11',
    'column-body.12',
    'column-button.13',
    'footnote.14'
  ],
  modals: [
    require('./B18.js')
  ]
};
