const Base = require('./Base');

require('@assets/css/popover.css');

module.exports = Base.extend({
  template: require('@templates/Popover.pug'),
  events: {
    'click .js-close': 'hide'
  },
  initialize (opts) {
    this.$parent = opts.$parent;
    this.systemEvents.on({
      'popover:hide': this.hide
    }, this);
  },
  render (title, body, top, left) {
    if (this.$el) {
      this.$el.remove();
    }

    this.setElement(
      this.template({
        title: title,
        body: body,
        top: top,
        left: left
      })
    );
    
    this.$el.appendTo(
      this.$parent
    );
  },
  show () {
    console.log('implement in extending junk...');
  },
  hide () {
    if (this.$el) {
      this.$el.remove();
    }
  },
  unload () {
    this.systemEvents.off(null, null, this);
  }
});
