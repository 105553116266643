const { isFunction } = require('underscore');
const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-title-with-buttons.css');

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenTitleWithButtons.pug'),
  screenType: 'screen-title-with-buttons',
  events: {
    'click .js-keys-button1': 'button1Clicked',
    'click .js-keys-button2': 'button2Clicked'
  },
  button1Clicked (ev) {
    if (isFunction(this.handlers.button1)) {
      this.handlers.button1(this, ev);
    }
  },
  button2Clicked (ev) {
    if (isFunction(this.handlers.button2)) {
      this.handlers.button2(this, ev);
    }
  }
});
