const $ = require('jquery');
const { isArray } = require('underscore');
const ScreenBaseCheckbox = require('./ScreenBaseCheckbox');

require('@assets/css/screen-text-over-checkboxes.css');

module.exports = ScreenBaseCheckbox.extend({
  template: require('@templates/ScreenTextOverCheckboxes.pug'),
  screenType: 'screen-text-over-checkboxes',
  events: {
    'change :checkbox': 'addChecked',
    'change :text': 'addText'
  },
  templateHelpers: {
    isChecked (num, response) {
      if (isArray(response)) {
        return response.findIndex(resp => {
          return resp == num;
        }) > -1;
      }
      
      return num == response;
    }
  },
  addText (ev) {
    let $el, name, value
    
    $el = $(ev.currentTarget);
    name = $el.attr('name');
    value = $el.val();
    
    this.store.responses.set(
      this.screenName,
      name,
      value
    );
  }
});
