var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (failedSave) {pug_html = pug_html + "\u003Cdiv class=\"screenReset\"\u003E\u003Cdiv class=\"contentWrap\"\u003E\u003Cdiv class=\"content\"\u003E";
if (failedSave) {
pug_html = pug_html + "\u003Ch2\u003EAn error occurred\u003C\u002Fh2\u003E\u003Cp\u003EParticipant response and feedback data could not be saved.\u003C\u002Fp\u003E\u003Cbutton class=\"js-tryToSave\"\u003ETry again\u003C\u002Fbutton\u003E";
}
else {
pug_html = pug_html + "\u003Ch2\u003EParticipant data has been saved\u003C\u002Fh2\u003E\u003Cp\u003EClick the next button to setup another participant.\u003C\u002Fp\u003E\u003Cbutton class=\"js-next\"\u003ENext\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"failedSave" in locals_for_with?locals_for_with.failedSave:typeof failedSave!=="undefined"?failedSave:undefined));;return pug_html;};
module.exports = template;