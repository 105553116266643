module.exports = {
  screen: 'B16',
  section: 'B',
  view: 'screen-two-column',
  screenKeys: [
    'title.1',
    'section.2',
    'body.3',
    'caption.4',
    'section.5',
    'body.6'
  ]
}
