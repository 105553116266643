var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (feedback, footnote, option, question, responses, title) {function isChecked (idx) { return responses.answer == idx }
pug_mixins["buildRadio"] = pug_interp = function(idx, text, responses){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["optionContainer",("option" + idx),isChecked(idx) ? "selected" : ""], [false,true,true]), false, true)+pug.attr("for", ("option" + idx), true, true)) + "\u003E\u003Cdiv class=\"answerText\"\u003E" + (null == (pug_interp = text) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"radio\"\u003E\u003Cdiv class=\"groupcopy2\"\u003E\u003Cimg class=\"outline\" src=\"\u002Fimg\u002Fradio-outline.png\"\u003E\u003C\u002Fdiv\u003E\u003Cimg class=\"filled\" src=\"\u002Fimg\u002Fradio-filled.png\"\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (pug.attr("id", ("option" + idx), true, true)+" type=\"radio\" name=\"answer\""+pug.attr("data-string", text, true, true)+pug.attr("data-feedback", (feedback ? feedback[idx] : false), true, true)+pug.attr("value", idx, true, true)+pug.attr("checked", isChecked(idx), true, true)) + "\u003E\u003C\u002Flabel\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"screenRadio\"\u003E\u003Cdiv class=\"title\"\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"question\"\u003E" + (null == (pug_interp = question) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"radioGroup js-radioGroup\"\u003E";
// iterate option
;(function(){
  var $$obj = option;
  if ('number' == typeof $$obj.length) {
      for (var idx = 0, $$l = $$obj.length; idx < $$l; idx++) {
        var opt = $$obj[idx];
pug_mixins["buildRadio"](idx, option[idx], responses);
      }
  } else {
    var $$l = 0;
    for (var idx in $$obj) {
      $$l++;
      var opt = $$obj[idx];
pug_mixins["buildRadio"](idx, option[idx], responses);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (footnote) {
pug_html = pug_html + "\u003Cdiv class=\"footnote\"\u003E" + (null == (pug_interp = footnote) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"feedback" in locals_for_with?locals_for_with.feedback:typeof feedback!=="undefined"?feedback:undefined,"footnote" in locals_for_with?locals_for_with.footnote:typeof footnote!=="undefined"?footnote:undefined,"option" in locals_for_with?locals_for_with.option:typeof option!=="undefined"?option:undefined,"question" in locals_for_with?locals_for_with.question:typeof question!=="undefined"?question:undefined,"responses" in locals_for_with?locals_for_with.responses:typeof responses!=="undefined"?responses:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;