module.exports = {
  screen: 'E8',
  section: 'E',
  view: 'screen-summary-report',
  hideNext: true,
  hideViolator: true,
  modals: [
    require('./C11')
  ],
  screenKeys: [
    'title',
    'body',
    'factsTitle',
    'factsPermanent',
    'factsReversed',
    'factsOthers',
    'decisionTitle',
    'decisionUnclear',
    'decisionForwardYes',
    'decisionForwardNo',
    'decisionMethodsTitle',
    'decisionForwardNotSure',
    'decisionHelpTitle',
    'decisionRightForYou',
    'decisionReady',
    'reflectionsTitle',
    'reflectionsTookTime',
    'reflectionsAdditionalTitle',
    'reflectionsTitleNoRank',
    'reflectionsNotesTitle',
    'reflectionsConsideredTitle',
    'reflectionsOthersTitle',
    'notesTitle',
    'notesButton',
    'topicsTitle',
    'topicsButton',
    'birthControlOptions',
    'comparedAgainstTitle',
    'comparisonLink',
    'vasectomyOption'
  ]
};
