const $ = require('jquery');
const { uniq } = require('underscore');
const { Sortable, Plugins } = require('@shopify/draggable');
const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-drag-drop.css');

const CAPACITY = 3;
const SORTABLE_CONTAINER = '.sortableContainer';
const RESPONSE_STORE_KEY = 'reasons';

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenDragDrop.pug'),
  screenType: 'screen-drag-drop',
  templateHelpers: {
    getDroppable (responses, totalReasons) {
      return (totalReasons || []).filter((r) => {
       return (responses.reasons || []).indexOf(r) === -1
      });
    },
    getDropped (responses, totalReasons) {
      return (responses.reasons || []).filter((reason) => {
        return totalReasons.indexOf(reason) > -1;
      });
    }
  },
  isFull (zone) {
    return (
      this.sortable.getDraggableElementsForContainer(zone).length
      === CAPACITY
    );
  },
  postRender () {
    this.dropzoneFull = false;
    this.$containers = this.$el.find(SORTABLE_CONTAINER);
    this.$zone = this.$('.js-zone');
    
    // setup a sortable environment
    this.sortable = new Sortable(this.$containers.get(), {
      delay: 15,
      draggable: '.dragItem',
      mirror: {
        appendTo: $('body')[0]
      }
    });
    
    this.sortable.on('drag:start', (evt) => {
      let [list, dropzone] = this.sortable.containers;
      let dropped = this.sortable.getDraggableElementsForContainer(
        dropzone
      ).length;
      
      this.dropzoneFull = (dropped === CAPACITY);
    });
    
    this.sortable.on('drag:stop', () => {
      let [list, dropzone] = this.sortable.containers;
      let dropped = this.sortable.getDraggableElementsForContainer(
        dropzone
      ).length;
      
      this.$zone.removeClass(
        'dropped-0 dropped-1 dropped-2 dropped-3'
      );
      this.$zone.addClass(`dropped-${dropped}`);
    });
    
    this.sortable.on('sortable:sort', (evt) => {
      let [list, dropzone] = this.sortable.containers;
      let fromList = evt.dragEvent.sourceContainer === list;
      let overDropzone = evt.dragEvent.overContainer === dropzone;
      
      if (fromList && overDropzone && this.dropzoneFull) {
        evt.cancel();
      }
    });
  },
  setItems () {
    // store the current state of things..
    this.store.responses.set(
      this.screenName,
      RESPONSE_STORE_KEY,
      uniq(this.$zone.find('.dragItem').get().map(el => {
        return $(el).data('reason');
      }))
    );
  },
  unload () {
    this.setItems();
  }
});
