const { isFunction } = require('underscore');
const ScreenBaseRadio = require('./ScreenBaseRadio');

require('@assets/css/screen-radio.css');

const LARC_METHODS = [0, 2, 3];

module.exports = ScreenBaseRadio.extend({
  template: require('@templates/ScreenRadio.pug'),
  screenType: 'screen-radio',
  postRender () {
    ScreenBaseRadio.prototype.postRender.apply(this, arguments);
    
    this.systemEvents.on({
      'link:larc': this.openLARC
    }, this);
  },
  openLARC () {
    this.modals[0].render(LARC_METHODS, true);
    this.modals[0].show();
  },
  unload () {
    this.destroyModals();
    this.systemEvents.off(null, null, this);
  }
});
