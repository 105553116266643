var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (button, cell, header, highlight, responses, subcell, title) {pug_mixins["methodCell"] = pug_interp = function(num, method, description, methodIdx, comparisonIdx, checked, disabled){
var block = (this && this.block), attributes = (this && this.attributes) || {};
let column = (num - 1) % 3;
let meChecked = checked || ((responses.methods || []).indexOf('' + comparisonIdx) > -1);
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["methodCell",`methodCell-${num}`,`column-${column}`], [false,true,true]), false, true)) + "\u003E\u003Cdiv class=\"background\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"rectangle\"\u003E\u003C\u002Fdiv\u003E\u003Cimg class=\"outline\" src=\"\u002Fimg\u002Foptions-table-cell-highlight.png\"\u003E\u003Cdiv" + (" class=\"method js-methodName\""+pug.attr("data-column", column, true, true)+pug.attr("data-method", methodIdx, true, true)) + "\u003E" + (null == (pug_interp = method) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"description\"\u003E" + (null == (pug_interp = description) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Clabel" + (pug.attr("class", pug.classes(["checkbox",(meChecked ? 'checked' : ''),(disabled ? 'disabled' : '')], [false,true,true]), false, true)+pug.attr("for", `method-${num}`, true, true)) + "\u003E\u003Cdiv class=\"box\"\u003E\u003C\u002Fdiv\u003E\u003Cimg class=\"checkWhite\" src=\"\u002Fimg\u002Foptions-table-cell-check-white.png\"\u003E\u003Cimg class=\"checkGrey\" src=\"\u002Fimg\u002Foptions-table-cell-check-grey.png\"\u003E\u003Cinput" + (" class=\"js-includeMethod\""+" type=\"checkbox\""+pug.attr("id", `method-${num}`, true, true)+" name=\"method\""+pug.attr("value", comparisonIdx, true, true)+pug.attr("checked", meChecked, true, true)+pug.attr("disabled", disabled, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["columnHeader"] = pug_interp = function(num, heading){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["columnHeader",`columnHeader-${num}`], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"columnText\"\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cimg class=\"arrow\" src=\"\u002Fimg\u002Foptions-table-header-arrow.png\"\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["filterOption"] = pug_interp = function(num, value, filterText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["filterOption",`filterOption-${num}`], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"filterText\"\u003E" + (null == (pug_interp = filterText) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Clabel" + (" class=\"radio\""+pug.attr("for", `filter-${num}`, true, true)) + "\u003E\u003Cdiv class=\"background\"\u003E\u003C\u002Fdiv\u003E\u003Cimg class=\"radioOutside\" src=\"\u002Fimg\u002Ffilter-deselected.png\"\u003E\u003Cimg class=\"radioInside\" src=\"\u002Fimg\u002Ffilter-selected.png\"\u003E\u003Cinput" + (" class=\"js-filterOption\""+" type=\"radio\""+pug.attr("id", `filter-${num}`, true, true)+" name=\"filterOption\""+pug.attr("value", num, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"screenOptionsTable\"\u003E\u003Cdiv class=\"title\"\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"sidebarFilters\"\u003E";
pug_mixins["filterOption"](1, 1, highlight[0]);
pug_mixins["filterOption"](3, 2, highlight[1]);
pug_mixins["filterOption"](2, 3, highlight[2]);
pug_mixins["filterOption"](4, 4, highlight[3]);
pug_html = pug_html + "\u003Cbutton class=\"g-hidden turnOffHighlights js-turnOffHighlights\"\u003E" + (null == (pug_interp = highlight[4]) ? "" : pug_interp) + "\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
pug_mixins["columnHeader"](1, header[0]);
pug_mixins["columnHeader"](2, header[1]);
pug_mixins["columnHeader"](3, header[2]);
pug_mixins["methodCell"](1, cell[0], subcell[0], 0, 0, true, true);
pug_mixins["methodCell"](2, cell[4], subcell[4], 6, 4);
pug_mixins["methodCell"](3, cell[8], subcell[8], 10, 8);
pug_mixins["methodCell"](4, cell[1], subcell[1], 1, 1);
pug_mixins["methodCell"](5, cell[5], subcell[5], 7, 5);
pug_mixins["methodCell"](6, cell[9], subcell[9], 11, 9);
pug_mixins["methodCell"](7, cell[2], subcell[2], 2, 2);
pug_mixins["methodCell"](8, cell[6], subcell[6], 8, 6);
pug_mixins["methodCell"](9, cell[10], subcell[10], 12, 10);
pug_mixins["methodCell"](10, cell[3], subcell[3], 5, 3);
pug_mixins["methodCell"](11, cell[7], subcell[7], 9, 7);
pug_mixins["methodCell"](12, cell[11], subcell[11], 13, 11);
pug_html = pug_html + "\u003Cbutton class=\"compareBtn js-compareMethods\" data-modal=\"0\" disabled\u003E\u003Cdiv class=\"compareBtn1\"\u003E" + (null == (pug_interp = button) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";}.call(this,"button" in locals_for_with?locals_for_with.button:typeof button!=="undefined"?button:undefined,"cell" in locals_for_with?locals_for_with.cell:typeof cell!=="undefined"?cell:undefined,"header" in locals_for_with?locals_for_with.header:typeof header!=="undefined"?header:undefined,"highlight" in locals_for_with?locals_for_with.highlight:typeof highlight!=="undefined"?highlight:undefined,"responses" in locals_for_with?locals_for_with.responses:typeof responses!=="undefined"?responses:undefined,"subcell" in locals_for_with?locals_for_with.subcell:typeof subcell!=="undefined"?subcell:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;