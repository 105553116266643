const { isUndefined } = require('underscore');

// build a mapping of section to index into the screens
// where that section starts
const bySection = require('../screens').reduce((h, s, i) => {
  if (isUndefined(h[s.section])) {
    // if we are navigating to section A,
    // (index 0) then we need to bump it
    // up to 2, so that we don't end up
    // on the study ID or launch screen
    h[s.section] = (!i
      ? i + 2
      : i
    );
  }
  
  return h;
}, {});

/**
 * Given a section, this will return the starting index
 * for that section.
 *
 * @param  {String} section the section to lookup
 * @return {Number}         index of the starting screen for
 * the indicated section
 */
module.exports = (section) => {
  return bySection[section];
}
