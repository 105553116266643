const $ = require('jquery');
const { uniq, difference, isArray, flatten } = require('underscore');
const stringHash = require('string-hash');
const ScreenBase = require('./ScreenBase');
const methods = require('@i18n/shared/methods.json');
const comparisons = require('@i18n/shared/comparisons.json');
const reasonStrings = require('@i18n/reasons.json');
const processText = require('../util/processText');
const store = require('../store');

// summary translations
const SUMMARY_TRANSLATIONS = {
  "C2": require('@i18n/summary/C2.json'),
  "D6": require('@i18n/summary/D6.json'),
  "D8": require('@i18n/summary/D8.json'),
  "D10": require('@i18n/summary/D10.json'),
  "E2": require('@i18n/summary/E2.json'),
  "E3": require('@i18n/summary/E3.json'),
  "E6": require('@i18n/summary/E6.json'),
  "C13": require('@i18n/summary/C13.json')
}

const YES = 0;
const NO = 1;
const NOT_SURE = 2;

const LANG_SPANISH = 'es';
const LANG_ENGLISH = 'en';

require('@assets/css/screen-summar-report.css');

// since the reasons are stored as raw strings,
// we will need to build a lookup for the english
// strings based on the location of where the spanish
// strings are in the translation tables... super
// inefficient, but hey, gets the job done...
function getEnglishReason (str) {
  let { en, es } = reasonStrings;
  let englishLookup = en.reduce((h, obj, i) => {
    if (!h[i]) {
      h[i] = {};
    }
    
    obj.reasons.forEach((r, j) => {
      h[i][j] = r;
    });
    
    return h;
  }, {});
  
  let spanishLookup = es.reduce((h, obj, i) => {
    obj.reasons.forEach((r, j) => {
      h[r] = {
        i: i,
        j: j
      };
    });
    
    return h;
  }, {});
  
  let coords = spanishLookup[str];
  
  // if we don't have lookup coordinates for the
  // string, it was a user entered value, so
  // just return the string
  if (!coords) {
    return str;
  } else {
    return englishLookup[coords.i][coords.j];
  }
}

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenSummaryReport.pug'),
  noteTemplate: require('@templates/ScreenSummaryReportNote.pug'),
  screenType: 'screen-summary-report',
  events: {
    'click .js-remove': 'removeContent',
    'click .js-print': 'printPage',
    'click .js-reset': 'resetApp',
    'click a[href=comparison]': 'openComparisonTable',
    'click .js-removeNote': 'removeNote',
    'click .js-addNote': 'addNote',
    'click .js-elevator': 'goingUp'
  },
  postRender () {
    this.store.meta.set('summaryReached', true);
    this.$notes = this.$('.js-notesList');
    this.$reset = this.$('.js-reset');
    
    this.systemEvents.on({
      'notes:refresh': this.refreshNotes
    }, this);
    
    if (this.store.meta.get('printed')) {
      this.$reset.removeClass('g-hidden');
    }
  },
  templateHelpers: {
    shouldShow (section) {
      return !store.meta.get(`hideContent-${section}`);
    },
    getSterilizationE3 () {
      let E3 = store.responses.get('E3');
      let language = store.meta.get('language');

      return processText(
        language,
        SUMMARY_TRANSLATIONS.E3[language][E3.answer]
      )
    },
    getSterilizationD10 () {
      let D10 = store.responses.get('D10');
      let language = store.meta.get('language');

      return processText(
        language,
        SUMMARY_TRANSLATIONS.D10[language][D10.answer]
      )
    },
    showDecisionUnclear () {
      let E3 = store.responses.get('E3');
      let D10 = store.responses.get('D10');

      return (
        E3.answer == 1 &&
        [1, 2, 3, 4, 5].indexOf(+D10.answer) > -1
      );
    },
    showDecisionForwardYes () {
      let E3 = store.responses.get('E3');

      return E3.answer == YES;
    },
    showDecisionForwardNo () {
      let E3 = store.responses.get('E3');

      return E3.answer == NO;
    },
    showDecisionForwardNotSure () {
      let E3 = store.responses.get('E3');

      return E3.answer == NOT_SURE;
    },
    decisionMethods () {
      let E3 = store.responses.get('E3');
      let E5 = store.responses.get('E5');
      let E7 = store.responses.get('E7');
      let language = store.meta.get('language');
      let others = [];
      let answers;
      
      if (E3.answer == YES) {
        return [];
      }
      
      if (E3.answer == NO) {
        if (E5.anotherMethod) {
          others.push(E5.anotherMethodText);
        }
        
        if (E5.notInterested) {
          others.push(E5.notInterestedText);
        }
        
        answers = E5.answer || [];
      } else if (E3.answer == NOT_SURE) {
        if (E7.anotherMethod) {
          others.push(E7.anotherMethodText);
        }
        
        if (E7.notInterested) {
          others.push(E7.notInterestedText);
        }
        
        answers = E7.answer || [];
      }
      
      return answers.map(m => {
        return processText(
          language,
          methods[language][m].name
        );
      }).concat(others);
    },
    decisionHelp () {
      let E3 = store.responses.get('E3');
      let E6 = store.responses.get('E6');
      let language = store.meta.get('language');
      let lookup = SUMMARY_TRANSLATIONS.E6[language];
      let results = (E6.answer || []).map(a => lookup[a]);
      let entered = E6.helpText;
      
      if (E3.answer != NOT_SURE) {
        return [];
      }
      
      if (E6.helpBox && entered && entered.length) {
        results.push(entered);
      }

      return results;
    },
    rightForYou () {
      let C2 = store.responses.get('C2');
      let language = store.meta.get('language');
      let a = C2['answer.1'];
      let b = C2['answer.2'];
      let c = C2['answer.3'];
      let d = C2['answer.4'];

      return [{
        text: processText(
          language,
          SUMMARY_TRANSLATIONS.C2[language]['answer.1'][a]
        ),
        id: 'rightForYou1',
        icon: a == 4 ? 'check' : 'warning'
      },{
        text: processText(
          language,
          SUMMARY_TRANSLATIONS.C2[language]['answer.2'][b]
        ),
        id: 'rightForYou2',
        icon: b == 0 ? 'check' : 'warning'
      },{
        text:  processText(
          language,
          SUMMARY_TRANSLATIONS.C2[language]['answer.3'][c]
        ),
        id: 'rightForYou3',
        icon: c == 4 ? 'check' : 'warning'
      },{
        text:  processText(
          language,
          SUMMARY_TRANSLATIONS.C2[language]['answer.4'][d]
        ),
        id: 'rightForYou4',
        icon: d == 4 ? 'check' : 'warning'
      }];
    },
    decisionReady () {
      let E2 = store.responses.get('E2');
      let language = store.meta.get('language');
      let a = E2['answer.1'];
      let b = E2['answer.2'];
      let c = E2['answer.3'];
      let d = E2['answer.4'];

      return [{
        text: processText(
          language,
          SUMMARY_TRANSLATIONS.E2[language]['answer.1'][a]
        ),
        id: 'decisionReady1',
        icon: a == YES ? 'check' : 'warning'
      },{
        text: processText(
          language,
          SUMMARY_TRANSLATIONS.E2[language]['answer.2'][b]
        ),
        id: 'decisionReady2',
        icon: b == YES ? 'check' : 'warning'
      },{
        text:  processText(
          language,
          SUMMARY_TRANSLATIONS.E2[language]['answer.3'][c]
        ),
        id: 'decisionReady3',
        icon: c == YES ? 'check' : 'warning'
      },{
        text:  processText(
          language,
          SUMMARY_TRANSLATIONS.E2[language]['answer.4'][d]
        ),
        id: 'decisionReady4',
        icon: d == YES ? 'check' : 'warning'
      }];
    },
    getReflections (englishPrint) {
      let D3 = store.responses.get('D3');
      let D4 = store.responses.get('D4');
      let others = store.responses.get('D3-others');
      let extras = [
        others.reason1,
        others.reason2,
        others.reason3
      ].filter(r => (r || '').trim().length);
      let extraLookup = extras.reduce((h, o) => {
        return h[o] = true, h;
      }, {});
      let total = [].concat(
        D3.reason,
        extras
      );
      
      if (englishPrint) {
        return (D4.reasons || []).filter((reason) => {
          return total.indexOf(reason) > -1;
        }).map(str => ({
          text: getEnglishReason(str),
          id: stringHash(str),
          isUser: extraLookup[str]
        })).filter(r => this.shouldShow(r.id));
      } else {
        return (D4.reasons || []).filter((reason) => {
          return total.indexOf(reason) > -1;
        }).map(str => ({
          text: str,
          id: stringHash(str),
          isUser: extraLookup[str]
        })).filter(r => this.shouldShow(r.id));
      }
    },
    additionalReasons (englishPrint) {
      let D3 = store.responses.get('D3');
      let D4 = store.responses.get('D4');
      let others = store.responses.get('D3-others');
      let extras = [
        others.reason1,
        others.reason2,
        others.reason3
      ].filter(r => (r || '').trim().length);
      let extraLookup = extras.reduce((h, o) => {
        return h[o] = true, h;
      }, {});
      
      if (englishPrint) {
        return difference(
          [].concat(
            D3.reason,
            extras
          ),
          D4.reasons
        ).filter(v => v).map(str => ({
          text: getEnglishReason(str),
          id: stringHash(str),
          isUser: extraLookup[str]
        })).filter(r => this.shouldShow(r.id));
      } else {
        return difference(
          [].concat(
            D3.reason,
            extras
          ),
          D4.reasons
        ).filter(v => v).map(str => ({
          text: str,
          id: stringHash(str),
          isUser: extraLookup[str]
        })).filter(r => this.shouldShow(r.id));
      }
    },
    whatMatters () {
      let D5 = store.responses.get('D5');
      return D5.notes;
    },
    makeDecision () {
      let language = store.meta.get('language');
      let D6 = store.responses.get('D6');
      
      return processText(
        language,
        SUMMARY_TRANSLATIONS.D6[language][D6.answer]
      );
    },
    getOthers () {
      let language = store.meta.get('language');
      let translations = SUMMARY_TRANSLATIONS.D8[language];
      
      return [
        store.responses.get('D8-1'),
        store.responses.get('D8-2'),
        store.responses.get('D8-3'),
        store.responses.get('D8-4')
      ].filter((other, idx, arr) => {
        
        // set it here, so we have the original index
        other.id = `otherPeople${idx}`;
        
        if (idx) {
          return (
            arr[idx - 1].doAnother &&
            other.fillOut == YES
          );
        }
        
        return other.fillOut == YES;
      }).map((other, idx) => {
        return {
          id: other.id,
          name: `${translations[0]} ${idx + 1}: ${other.name || '-'}`,
          discussed: translations[1][other.discussed],
          think: translations[2][other.think],
          pressure: translations[3][other.pressure],
          support: translations[4][other.support],
          supportHow: other.supportHow
        }
      }).filter(o => this.shouldShow(o.id));
    },
    knowVasectomy () {
      let C13 = store.responses.get('C13');
      let language = store.meta.get('language');
      
      return processText(
        language,
        SUMMARY_TRANSLATIONS.C13[language][C13.answer]
      );
    },
    getCompared () {
      let storedComparisons = store.responses.get('comparisons');
      let language = store.meta.get('language');

      return flatten(uniq((storedComparisons.methods || []).map(mthd => {
        if (isArray(comparisons[language][mthd])) {
          return comparisons[language][mthd].map(m => {
            return processText(
              language,
              m.type
            );
          })
        }
        
        return processText(
          language,
          comparisons[language][mthd].type
        );
      }))).map(text => ({
        text: text,
        id: stringHash(text)
      }));
    },
    getNotes () {
      return store.notes.all();
    },
    getTopics () {
      return store.topics.all();
    }
  },
  openComparisonTable (ev) {
    let { methods } = store.responses.get('comparisons');
    
    this.modals[0].render(methods);
    this.modals[0].show();
  },
  removeContent (ev) {
    let $el, $content, $section;
    
    $el = $(ev.currentTarget);
    $content = $el.closest('.js-content');
    $section = $el.closest('.js-section');
    id = $content.attr('id');
    
    // remove the element
    $content.remove();
    
    // figure out if we have any additional content,
    // if not, remove the section
    if (!$section.find('.js-content').length) {
      $section.remove();
    }
    
    if (id) {
      store.meta.set(`hideContent-${id}`, true);
    }
  },
  addNote (ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.systemEvents.trigger('notes:open');
  },
  removeNote (ev) {
    let $el = $(ev.currentTarget);
    let $note = $el.closest('.js-content');
    let id = $note.data('id');
    
    this.store.notes.remove(id);
    $note.remove();
  },
  refreshNotes () {
    let notes = this.store.notes.all();
    let $section =  this.$notes.closest('.js-section');
    
    this.$notes.find('.js-note').remove();
    
    if (notes.length) {
      $section.removeClass('g-hidden');
      this.$notes.prepend(
        notes.map(note => {
          return $(
            this.noteTemplate(note)
          );
        })
      );
    } else {
      $section.addClass('g-hidden');
    }
    
  },
  appendEnglish () {
    if (this.$englishContent) {
      this.$englishContent.remove();
    }
    
    // temporarily switch to English so that
    // anything relying on this store setting
    // uses the proper language
    store.meta.set('language', LANG_ENGLISH);
    
    this.$englishContent = $(this.template({
      screenIndex: this.screenIndex,
      screenType: this.screenType,
      screenName: this.screenName,
      language: LANG_ENGLISH,
      studyId: this.store.meta.get('studyId'),
      responses: this.store.responses.get(
        this.screenName
      ),
      englishPrint: true,
      helpers: this.templateHelpers,
      ...this.templateOptionsEnglish
    })).addClass(
      'screenSummaryReport-inner screenSummaryReport-englishContent'
    ).insertAfter(this.$el);
    
    // after rendering out the English summary,
    // switch back to Spanish
    store.meta.set('language', LANG_SPANISH);
  },
  printPage () {
    let currentLang = store.meta.get('language');
    
    // check if we in Spanish and if we are
    // do the ole English append
    if (currentLang === LANG_SPANISH) {
      this.appendEnglish();
    }
    
    window.print();
    this.store.meta.set('printed', true);
    this.$reset.removeClass('g-hidden');
  },
  resetApp (ev) {
    ev.stopPropagation();
    ev.preventDefault();
    
    window.location = '#-2';
  },
  goingUp (ev) {
    ev.stopPropagation();
    ev.preventDefault();
    
    $('.js-screenContent').scrollTop(0);
  }
});
