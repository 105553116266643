const E4 = require('./E4');
const E5 = require('./E5');
const E6 = require('./E6');

const YES = 0;
const NO = 1;
const NOT_SURE = 2;

module.exports = (store) => {
  // hacky, at best, at worst
  // an abomination...
  if (!store) {
    return {
      section: 'E'
    };
  }
  
  let { answer } = store.responses.get('E3');
  
  if (answer == YES) {
    return E4;
  } else if (answer == NO) {
    return E5;
  } else {
    return E6;
  }
}
