const marked = require('marked');
const { isString } = require('underscore');
const highlight = require('../glossary/highlight');

const mdProcessor = marked.setOptions({
  renderer: new marked.Renderer(),
  smartypants: true,
  breaks: true
});

/**
 * Given some text, this will do a replacement on any of the Markdown-y
 * things that exist in there. It will also process any highlighted
 * text.
 *
 * @param  {String} language current application language
 * @param  {String} text     string that has markdown-y things in it.
 * @param  {Object} memo     for us to keep track of what we found in
 * area of highlightin
 * @return {String}      string with any identified replacemnets made
 */
module.exports = function (language, text, conf, counts, noHighlight) {
  if (!isString(text)) {
    return text;
  } else if (!text) {
    return '';
  }
  
  var re = /^<p>|<\/p>\n?$/ig; // lose the <p>,</p> tags
  var md = mdProcessor(text);
  var mdClean = md.replace(re, '');

  if (noHighlight) {
    return mdClean;
  }
  
  return highlight(
    language,
    mdClean,
    conf,
    counts
  );
}
