const methods = require('@i18n/shared/methods.json');
const translations = require('@i18n/main.json');

const E7 = Object.assign({}, translations.E7);

E7.en.methods = Object.assign({}, methods.en);
E7.es.methods = Object.assign({}, methods.es);

E7.en.anotherMethod = methods.en[methods.en.length - 2].name;
E7.en.notInterested = methods.en[methods.en.length - 1].name;

E7.es.anotherMethod = methods.es[methods.es.length - 2].name;
E7.es.notInterested = methods.es[methods.es.length - 1].name;


module.exports = E7;
