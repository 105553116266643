module.exports =  {
  screen: 'E8-notes',
  section: 'E',
  view: 'screen-notes-last-chance',
  nextSummary: true,
  hideNext: true,
  screenKeys: [
    'title.1',
    'body.2'
  ]
}
