const axios = require('axios');
const saveResponses = require('../api/saveResponses');
const saveFeedback = require('../api/saveFeedback');
const saveNotes = require('../api/saveNotes');

/**
 * Perform requests to save participant data.
 *
 * @return {Promise}
 */
module.exports = function () {
  return new Promise((resolve, reject) => {
    axios.all([
      saveResponses(),
      saveFeedback(),
      saveNotes()
    ]).then(axios.spread((responses, feedback, notes) => {
      if (!responses) {
        console.error('failed to save response data.');
      }
      
      if (!feedback) {
        console.error('failed to save feedback data');
      }
      
      if (!notes) {
        console.error('failed to save notes data');
      }
      
      if (!responses || !feedback || !notes) {
        reject('failed to save user data');
      } else {
        resolve();
      }
    }));
  });
}
