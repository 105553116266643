const terms = require('./terms.json');

// keep track of the terms we have seen
const SEEN_TERMS = {};

// given a term, this will build the appropriate
// regex to find matches
function searchRegex (term) {
  return new RegExp(
    `\\b\\(?(${term})\\)?\\b`,
    'i'
  );
}

/**
 * Given a content string, this function will search the string
 * for
 *
 * @param  {String} language the current language
 * @param  {String} str      the content to search for terms to highlight
 * @param  {Object} memo     object to keep track of what we found, thus
 * far. terms in here will no longer be highlighted
 * @return {String}     processed content with any terms highlighted
 */
module.exports = function (language, str, conf = {}, counts = {}) {
  let lookFor = Object.keys(terms[language]).filter(t => {
    // keep a term if it has not been previously seen or
    // if it was previously seen on this screen and we have
    // not yet seen this term on this screen.... geesh
    return (
      !SEEN_TERMS[t] ||
      (SEEN_TERMS[t] === conf.screen && !counts[t])
    );
  }).map(t => ({
    term: t,
    definition: terms[language][t]
  }));
  
  return lookFor.reduce((s, { term }) => {
    return s.replace(searchRegex(term), (match, exact) => {
      // make this term as found
      SEEN_TERMS[term] = conf.screen;
      counts[term] = 1;
      
      // highlight the term
      return `<span class="term-highlight js-term" data-term="${term}">${exact}</span>`;
    });
  }, str);
}
