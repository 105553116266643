const B3 = require('./B3');

module.exports = {
  screen: 'B2',
  section: 'B',
  view: 'screen-video-intro',
  screenKeys: [
    'title.1',
    'videoTitle.2'
  ],
  videoSource (language) {
    return B3.videoSource(language);
  }
};
