const $ = require('jquery');
const { isFunction } = require('underscore');
const ScreenBaseRadio = require('./ScreenBaseRadio');

require('@assets/css/screen-multiple-likert-other.css');

module.exports = ScreenBaseRadio.extend({
  template: require('@templates/ScreenMultipleLikertOther.pug'),
  screenType: 'screen-multiple-likert-other'
});
