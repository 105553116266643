const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-two-column-sidebar.css');

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenTwoColumnSidebar.pug'),
  screenType: 'screen-two-column-sidebar',
  events: {
    'click .js-coinToggle': 'flipCoin'
  },
  flipCoin () {
    let $coin = this.$('.js-coin').removeClass('heads tails');

    setTimeout(() => {
      $coin.addClass(
        (Math.random() <= 0.5) ? 'heads' : 'tails'
      );
    }, 0);
  }
});
