var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (body, helpers, reasons, responses, section, title) {pug_mixins["dragItem"] = pug_interp = function(reason){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (" class=\"dragItem\""+pug.attr("data-reason", reason, true, true)) + "\u003E\u003Cdiv class=\"anima-valign-text-middle dragReason\"\u003E" + (null == (pug_interp = reason) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"screenDragDrop dragdrop\"\u003E\u003Cdiv class=\"title\"\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"body\"\u003E" + (null == (pug_interp = body) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"section1 anima-valign-text-bottom\"\u003E" + (null == (pug_interp = section[1]) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"scrollinglist\"\u003E\u003Cdiv class=\"itemContainer sortableContainer\"\u003E";
let droppableReasons = helpers.getDroppable(responses, reasons);
// iterate droppableReasons
;(function(){
  var $$obj = droppableReasons;
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var reason = $$obj[i];
pug_mixins["dragItem"](reason);
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var reason = $$obj[i];
pug_mixins["dragItem"](reason);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"arrow\"\u003E\u003Cimg class=\"path5\" src=\"\u002Fimg\u002Fdrag-drop-path-5@2x.png\"\u003E\u003Cimg class=\"path12\" src=\"\u002Fimg\u002Fdrag-drop-path-12@2x.png\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"section anima-valign-text-bottom\"\u003E" + (null == (pug_interp = section[0]) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["dragList","js-zone",`dropped-${(responses.reasons || []).length}`], [false,false,true]), false, true)) + "\u003E\u003Cdiv class=\"a1 anima-valign-text-middle\"\u003E1\u003C\u002Fdiv\u003E\u003Cdiv class=\"a2 anima-valign-text-middle\"\u003E2\u003C\u002Fdiv\u003E\u003Cdiv class=\"a3 anima-valign-text-middle\"\u003E3\u003C\u002Fdiv\u003E\u003Cdiv class=\"drop\"\u003E\u003Cdiv class=\"mask sortableContainer\"\u003E";
let droppedReasons = helpers.getDropped(responses, reasons);
// iterate droppedReasons
;(function(){
  var $$obj = droppedReasons;
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var reason = $$obj[i];
pug_mixins["dragItem"](reason);
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var reason = $$obj[i];
pug_mixins["dragItem"](reason);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"body" in locals_for_with?locals_for_with.body:typeof body!=="undefined"?body:undefined,"helpers" in locals_for_with?locals_for_with.helpers:typeof helpers!=="undefined"?helpers:undefined,"reasons" in locals_for_with?locals_for_with.reasons:typeof reasons!=="undefined"?reasons:undefined,"responses" in locals_for_with?locals_for_with.responses:typeof responses!=="undefined"?responses:undefined,"section" in locals_for_with?locals_for_with.section:typeof section!=="undefined"?section:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;