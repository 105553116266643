const reasons = require('@i18n/reasons.json');

module.exports = {
  "en": {
    "title": "What is causing you to consider permanent birth control?",
    "body": "Select any reasons that apply.",
    "button": "Add another reason",
    "sections": reasons.en
  },
  "es": {
    "title": "¿Qué le está causando a que usted considere el control de la natalidad permanente?",
    "body": "Seleccione las razónes que aplican.",
    "button": "Agregue otra razón",
    "sections": reasons.es
  }
}
