const $ = require('jquery');
const Popover = require('./Popover');
const terms = require('../glossary/terms.json');

const MARGIN = 15;

const $body = $('body');

module.exports = Popover.extend({
  show ($el, feedback) {
    let $text = $el.closest('label').find('.answerText');
    let width = $text.width();
    let bodyOffset = $body.offset();
    let { left, top } = $text.offset();
    let [title, ...body] = feedback.split('<br>');
    
    this.render(
      title,
      body.join('<br><br>'),
      top - bodyOffset.top,
      (left - bodyOffset.left) + width + MARGIN
    );
  }
});
