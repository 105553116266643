const Dexie = require('dexie').default;
const axios = require('axios');
const async = require('async');

const db = new Dexie('my-decision');

const VIDEOS = [{
  name: 'video-1-en',
  url: '/videos/video-1-en.mp4'
}, {
  name: 'video-2-en',
  url: '/videos/video-2-en.mp4'
}, {
  name: 'video-1-es',
  url: '/videos/video-1-es.mp4'
}, {
  name: 'video-2-es',
  url: '/videos/video-2-es.mp4'
}];

function download (db, url, storageName, done) {
  db.videos.get(storageName, (record) => {
    if (!record) {
      return axios({
        method: 'GET',
        url: url,
        responseType: 'blob'
      }).then(({ data }) => {
        db.videos.put({
          name: storageName,
          video: data
        }).then(() => {
          db.videos.get(storageName, (record) => {
            console.log(`downloaded and saved ${url}`);
            done(false, URL.createObjectURL(record.video));
          }).catch(done);
        }).catch(done);
      }).catch(done);
    }
    
    db.videos.get(storageName, (record) => {
      console.log(`${url} already downloaded.`);
      done(false, URL.createObjectURL(record.video));
    }).catch(done);
  }).catch(done);
}

/**
 * Attempts to download videos and store them locally. Returns an
 * object of the format:
 *
 *  {
 *    <video_name> : {
 *      localUri: <reference to locally stored video>,
 *      url: <remote url (for fallback)>
 *    }
 *    ...
 *  }
 *
 * @param  {Function} done callback function. first parameter will be an
 * error or falsy for no error. the second parameter will be an object
 * of the above format.
 */
module.exports = (done) => {
  // initialize the store
  db.version(1).stores({
    videos: 'name'
  });
  
  db.open().then(() => {
    // download the videos
    async.map(VIDEOS, (video, dn) => {
      download(db, video.url, video.name, (err, localUri) => {
        if (err) {
          console.error(`issue downloading ${video.url}`, err);
          return dn(null, {
            name: video.name,
            url: video.url,
            localUri: false
          });
        }
        
        dn(null, {
          name: video.name,
          url: video.url,
          localUri: localUri
        });
      });
    }, (err, videos) => {
      if (err) {
        return done(err);
      }
      
      done(null, videos.reduce((h, v) => {
        return h[v.name] = v, h;
      }, {}))
    });
  }).catch((err) => {
    console.error(err);
    console.log('failed to open IndexedDB. will not store videos locally.');
    done();
  });
}
