const { E9 } = require('@i18n');

module.exports = {
  screen: 'FAQ',
  section: 'FAQ',
  view: 'screen-faq',
  screenKeys: [
    'title',
    'sections'
  ],
  preConditions: {
    // the title is the language map, we can just leave it there
    // and pull from it when we resolve the screen...
    'title': function (store) {
      let language = store.meta.get('language');
      
      return E9[language]['title.1'];
    }
  }
};
