const $ = require('jquery');
const LayoutMain = require('./views/LayoutMain');
const PopoverTerm = require('./views/PopoverTerm');
const systemEvents = require('./util/SystemEvents');
const prepareBodyEvents = require('./util/prepareBodyEvents');
const downloadVideos = require('./util/downloadVideos');

const $body = $('body');
const $layout = $('.js-insertHere');

const $loadingMessage = $('<div class="videosLoading"/>').text(
  'Downloading videos... this may take some time.'
);

require('@assets/css/site.css');

/**
 * This is the application entry point.
 */
$(() => {
  let layoutMain, popoverTerm;
  
  // show some text to indicate that the videos are currently downloading
  $loadingMessage.appendTo($layout);
  
  // attempt to download and store the videos
  // locally.
  downloadVideos((err, localVideos) => {
    if (err) {
      console.error(err);
    }
    
    // k done loading. remove the message.
    $loadingMessage.remove();
    
    // store them globally here, so we can
    // access them from wherever... this is
    // not the best support for modularity,
    // but, eh, it works...
    window.localVideos = localVideos;
    
    popoverTerm = new PopoverTerm({
      $parent: $body
    });
    
    layoutMain = new LayoutMain({
      $parent: $layout,
      popover: popoverTerm
    });
    
    prepareBodyEvents(
      $body,
      systemEvents,
      popoverTerm
    );
    
    window.layoutMain = layoutMain;
  });
});
