const $ = require('jquery');
const ScreenBase = require('./ScreenBase');
const PopoverMethod = require('./PopoverMethod');
const methods = require('@i18n/shared/methods.json');

require('@assets/css/screen-options-table.css');

const HIGHLIGHTS = {
  1: [1, 4, 7, 10],
  2: [1, 3, 4, 6, 9, 7, 12],
  3: [1, 4, 7, 10],
  4: [1, 2, 7, 10,]
};

const $body = $('body');

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenOptionsTable.pug'),
  screenType: 'screen-options-table',
  events: {
    'change .js-filterOption:radio': 'changeFilter',
    'change .js-includeMethod:checkbox': 'includeMethod',
    'click .js-turnOffHighlights': 'clearHighlights',
    'click .js-compareMethods': 'compareMethods',
    'click .js-methodName': 'showMethodPopover'
  },
  postRender () {
    let checked = this.$('[type=checkbox]:checked');
    
    this.methodPopover = new PopoverMethod({
      $parent: $body
    });
    
    this.$turnOffHighlights = this.$('.js-turnOffHighlights')
    
    if (checked.length > 1) {
      this.$('.js-compareMethods').removeAttr('disabled');
    } else {
      this.$('.js-compareMethods').attr({
        disabled: 'disabled'
      });
    }
  },
  changeFilter (ev) {
    let $el = $(ev.currentTarget);
    let value = $el.val();
    let toHighlight = HIGHLIGHTS[value];
    
    this.$('.selected').removeClass('selected');
    this.$('.highlight').removeClass('highlight');
    
    if ($el.is(':checked')) {
      $el.closest('label').addClass('selected');
      (toHighlight || []).forEach((method) => {
        this.$(`.methodCell-${method}`).addClass('highlight');
      });
    }
    
    this.$turnOffHighlights.removeClass('g-hidden');
  },
  includeMethod (ev) {
    let $el = $(ev.currentTarget);
    let checked = this.$('[type=checkbox]:checked');
    
    if (checked.length > 4) {
      $el.prop('checked', false).change();
      return false;
    }
    
    if ($el.is(':checked')) {
      $el.closest('label').addClass('checked');
    } else {
      $el.closest('label').removeClass('checked');
    }
    
    this.store.responses.set(
      this.screenName,
      'methods',
      checked.get().map((el) => $(el).val())
    );
    
    if (checked.length > 1) {
      this.$('.js-compareMethods').removeAttr('disabled');
    } else {
      this.$('.js-compareMethods').attr({
        disabled: 'disabled'
      });
    }
  },
  clearHighlights () {
    this.$('.js-filterOption:radio:checked').prop('checked', false).change();
    this.$turnOffHighlights.addClass('g-hidden');
  },
  compareMethods (ev) {
    let $el = $(ev.currentTarget);
    let modal = $el.data('modal');
    let responses = this.store.responses.get(this.screenName);
    
    
    this.store.responses.set(
      'comparisons',
      'methods',
      responses.methods
    );
    
    this.modals[modal].render(
      responses.methods
    );
    
    this.modals[modal].show();
  },
  showMethodPopover (ev) {
    let $cell = $(ev.currentTarget);
    let column = $cell.data('column');
    let method = $cell.data('method');
    
    this.methodPopover.render(
      method,
      column,
      $cell
    );
  },
  unload () {
    if (this.methodPopover) {
      this.methodPopover.hide();
    }
    
    this.destroyModals();
    this.systemEvents.off(null, null, this);
  }
});
