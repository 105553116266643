const $ = require('jquery');
const ScreenBaseModal = require('./ScreenBaseModal');
const processText = require('../util/processText');

require('@assets/css/screen-faq.css');

module.exports = ScreenBaseModal.extend({
  events: {
    'click .js-close': 'hide',
    'click .js-scrollTo': 'scrollTo'
  },
  template: require('@templates/ScreenFaq.pug'),
  screenType: 'screen-faq',
  templateHelpers: {
    processText: processText
  },
  postShow: function (section) {
    if (section) {
      this.goToSection(section);
    }
  },
  scrollTo (ev) {
    let $el, section;

    $el = $(ev.currentTarget);
    section = $el.data('section');

    this.goToSection(section);

    ev.preventDefault();
    ev.stopPropagation();
  },
  goToSection (section) {
    let $section = this.$(`#${section}`);
    if ($section.length) {
      $section[0].scrollIntoView();
    }
  },
  watchSidebar () {
    let $wrap = this.$('.screenFaq-wrap');
    let $sidebar = this.$('.screenFaq-sidebar');
    let $close = this.$('.js-close');
    let top = -$wrap.position().top;

    if (top > 0) {
      $close.addClass('fixed');
    } else {
      $close.removeClass('fixed');
    }

    if (top > 138) {
      $sidebar.addClass('fixed');
    } else {
      $sidebar.removeClass('fixed');
    }
  }
});
