module.exports = {
  screen: 'C13',
  section: 'C',
  view: 'screen-bottom-likert',
  screenKeys: [
    'title.1',
    'body.2',
    'question.3',
    'option.4',
    'option.5',
    'option.6',
    'option.7'
  ]
};
