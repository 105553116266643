const uuid = require('uuid');

/**
 * Topics store.
 *
 * @type {Object}
 */
module.exports = {
  store: require('store'),
  
  /**
   * Adds a topic the topic storage module.
   *
   * @param {Object} topic a topic of the form:
   *
   * {
   *   screen: <screen identifier>,
   *   text: <the topic text>
   * }
   *
   * a UUID for the topic will be generated when inserted
   */
  add (topicToAdd) {
    let topics;
    
    if (!this.store.get('topics')) {
      this.store.set('topics');
    }
    
    topics = this.store.get('topics');
    
    topics.push(
      Object.assign(
        {},
        topicToAdd,
        {
          id: uuid()
        }
      )
    );
    
    this.store.set('topics', topics);
  },
  
  /**
   * Given a topic, this will remove the topic from the store.
   *
   * @param  {Object} topic the topic to be removed
   */
  remove (topicToRemove) {
    let topics, idx;
    
    if (!this.get('topics')) {
      this.store.set('topics', []);
    }
    
    topics = this.store.get('topics');
    
    idx = topics.findIndex(topic =>
      topic.id === topicToRemove.id
    );
    
    if (idx > -1) {
      topics = topics.splice(idx, 1);
    }
  },
  
  /**
   * Returns a list of all available notes in the system.
   *
   * @return {Array} a listing of all notes in the system
   */
  all () {
    if (!this.store.get('topics')) {
      this.store.set('topics', []);
    }
    
    return this.store.get('topics');
  },
  
  /**
   * Retrieves topics for a particular screen.
   *
   * @param  {String} screen the screen to grab topics for
   * @return {Array}        an array of any topics that have
   * been associated with this screen
   */
  forScreen (screen) {
    if (!this.store.get('topics')) {
      this.store.set('topics', []);
    }
    
    return this.store.get('topics').filter(topic =>
      topic.screen === screen
    );
  },
  
  /**
   * Get a JSON representation of this store.
   *
   * @return {String} JSON representation of this store
   */
  json () {
    return JSON.stringify(
      this.store.get('topics')
    );
  },
  
  /**
   * Resets the topics to an empty state
   */
  reset () {
    this.store.set('topics', []);
  }
}
