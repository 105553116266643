const youSaidTranslations = require('@i18n/summary/C2.json');
const processText = require('../../util/processText');

function getSubscreen (responses) {
  let one = responses['answer.1'];
  let two = responses['answer.2'];
  let three = responses['answer.3'];
  let four = responses['answer.4'];
  
  if (one == 4 && two == 0 && three == 4 && four == 4) {
    return 'C4';
  } else {
    return 'C3';
  }
}

module.exports = {
  screen: 'C3-C4',
  section: 'C',
  view: 'screen-full-text',
  screenKeys: [
    'title.1',
    'body.2',
    'youSaid'
  ],
  preConditions: {
    'title.1': function (store, translations, language) {
      let responses = store.responses.get('C2');
      let subScreen = getSubscreen(responses);
      
      return translations[subScreen][language]['title.1'];
    },
    'body.2': function (store, translations, language) {
      let responses = store.responses.get('C2');
      let subScreen = getSubscreen(responses);
      
      return translations[subScreen][language]['body.2'];
    },
    youSaid: function (store, translations, language) {
      let responses = store.responses.get('C2');
      let one = responses['answer.1'];
      let two = responses['answer.2'];
      let three = responses['answer.3'];
      let four = responses['answer.4'];
      let subscreen = getSubscreen(responses);
      
      // if this is screen C3, then go ahead and figure out which ones to
      // show
      if (subscreen === 'C3') {
        return [
          (one != 4
            ? processText(
                language,
                youSaidTranslations[language]['answer.1'][one]
              )
            : false
          ),
          (two != 0
            ? processText(
                language,
                youSaidTranslations[language]['answer.2'][two]
              )
            : false
          ),
          (three != 4
            ? processText(
                language,
                youSaidTranslations[language]['answer.3'][three]
              )
            : false
          ),
          (four != 4
            ? processText(
                language,
                youSaidTranslations[language]['answer.4'][four]
              )
            : false
          )
        ].filter(v => v);
      } else {
        return [
          processText(language, youSaidTranslations[language]['answer.1'][one]),
          processText(language, youSaidTranslations[language]['answer.2'][two]),
          processText(language, youSaidTranslations[language]['answer.3'][three]),
          processText(language, youSaidTranslations[language]['answer.4'][four])
        ];
      }
    }
  }
};
