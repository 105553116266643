const B11 = require('./B11');

module.exports = {
  screen: 'B10',
  section: 'B',
  view: 'screen-video-intro',
  screenKeys: [
    'title.1',
    'videoTitle.2'
  ],
  videoSource (language) {
    return B11.videoSource(language);
  }
};
