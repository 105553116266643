module.exports = {
  screen: 'NotesFeedback',
  section: 'A',
  view: 'screen-feedback-panel-explainer',
  screenKeys: [
    'note.1',
    'note.2',
    'note.3',
    'note.4',
    'note.5',
    'note.6',
    'note.7',
    'note.8',
    'feedback.9',
    'feedback.10',
    'feedback.11',
    'feedback.12',
    'feedback.13',
    'feedback.14',
    'feedback.15'
  ]
}
