module.exports = {
  screen: 'C11',
  section: 'C',
  view: 'screen-comparison-table',
  screenKeys: [
    'label.1',
    'label.2',
    'label.3',
    'label.4',
    'label.5',
    'label.6',
    'label.7',
    'label.8',
    'footnote.9'
  ]
};
