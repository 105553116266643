module.exports = {
  screen: 'B3',
  section: 'B',
  view: 'screen-video',
  screenKeys: [],
  videoSource (language) {
    let videoDef = window.localVideos[
      `video-1-${language}`
    ];
    
    return (videoDef.localUri
      ? videoDef.localUri
      : videoDef.url
    );
  },
  doPlay: true,
  conditionalNext: true
};
