const $ = require('jquery');
const { chain, isFunction } = require('underscore');
const ScreenBase = require('./ScreenBase');
const PopoverFeedback = require('./PopoverFeedback');

/**
 * This will serve as a base screen for ALL radio and likert
 * screens.
 *
 * It currently just supplies the necessary shared event handling,
 * the extending screens will still supply necessary templates n'at.
 *
 * @type {Backbone.View}
 */
module.exports = ScreenBase.extend({
  conditionalNext: true,
  events: {
    'change :radio': 'optionChanged',
    'change :checkbox': 'checkboxChanged',
    'change :text': 'saveText',
    'change textarea': 'saveText'
  },
  postRender () {
    this.feedbackPopover = new PopoverFeedback({
      $parent: this.$el
    });
  },
  optionChanged (ev) {
    let $el = $(ev.currentTarget);
    let name = $el.attr('name');
    let value = $el.val();
    let string = $el.data('string');
    let feedback = $el.data('feedback');
    
    $el.closest('.js-radioGroup').find('.selected').removeClass('selected');
    $el.closest('label').addClass('selected');
    
    if (isFunction(this.handlers.option)) {
      this.handlers.option(name, value, this, ev);
    } else {
      this.store.responses.set(
        this.screenName,
        name,
        value
      );
    }
    
    if (feedback) {
      this.feedbackPopover.show($el, feedback);
    }
    
    // check if this screen has met the criteria
    // of enabling the next button
    this.enableNextMaybe();
    
    if (isFunction(this.optionChangedPlus)) {
      this.optionChangedPlus($el, name, value);
    }
  },
  checkboxChanged (ev) {
    let $el = $(ev.currentTarget);
    let name = $el.attr('name');
    let value = ($el.is(':checked')
      ? true
      : false
    );
    
    if (value) {
      $el.closest('label').addClass('checked');
    } else {
      $el.closest('label').removeClass('checked');
    }
    
    if (isFunction(this.handlers.option)) {
      this.handlers.option(name, value, this, ev);
    } else {
      this.store.responses.set(
        this.screenName,
        name,
        value
      );
    }
  },
  saveText (ev) {
    let $el = $(ev.currentTarget);
    let name = $el.attr('name');
    let value = $el.val();
    
    if (isFunction(this.handlers.option)) {
      this.handlers.option(name, value, this, ev);
    } else {
      this.store.responses.set(
        this.screenName,
        name,
        value
      );
    }
  },
  enableNextMaybe () {
    let total = chain(this.$(':radio').get())
                     .pluck('name')
                     .uniq()
                     .value()
                     .length;
    let checked = this.$(':radio:checked').length;
    
    // if we have a non-zero checked count
    // and that count is equal to the total
    // number of unique radio groups
    if (checked && (checked === total)) {
      this.systemEvents.trigger('next:enable')
    }
  },
  unload () {
    this.systemEvents.off(null, null, this);
  }
});
