module.exports = {
  screen: 'A0',
  section: 'A',
  view: 'screen-study-id',
  noNavigation: true,
  noControls: true,
  screenKeys: [
    'item.1'
  ]
};
