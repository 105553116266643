const $ = require('jquery');

/**
 * Sets up any global event handlers (e.g. body click to close).
 *
 * @param  {Element}       $body        jQuery enhanced DOM element for the body
 * @param  {Object}        systemEvents the system events object
 * @param  {Backbone.View} popoverTerm  backbone view for the PopoverTerm
 */
module.exports = ($body, systemEvents, popoverTerm) => {
  // handle the dismissing of popovers/panels/modals
  $body.on('click', (ev) => {
    let $el = $(ev.target);
    
    if (!$el.closest('.popover').length) {
      systemEvents.trigger('popover:hide');
    }
    
    if (!$el.closest('.notesFeedbackPanel').length) {
      systemEvents.trigger('panel:close');
    }
    
    if (!$el.closest('.js-modalContent').length &&
        !$el.closest('.notesFeedbackPanel').length &&
        !$el.closest('.popover').length) {
      systemEvents.trigger('modals:close');
    }
  });
  
  // handle the clicking of term/glossary popovers
  $body.on('click', '.js-term', (ev) => {
    let $el = $(ev.currentTarget);
    
    ev.preventDefault();
    ev.stopPropagation();
    
    popoverTerm.show($el);
  });
  
  // handle anchors in the application
  $body.on('click', 'a', (ev) => {
    let $el = $(ev.currentTarget);
    
    if (!$el.attr('href')) {
      return;
    }
    
    let href = $el.attr('href').split('-');
    
    ev.preventDefault();
    ev.stopPropagation();
    
    systemEvents.trigger(
      `link:${href[0].toLowerCase()}`,
      ...href.slice(1)
    );
  });
}
