const { isFunction } = require('underscore');
const ScreenBase = require('./ScreenBase');

/**
 * This will serve as a base screen for ALL modal screens.
 *
 * Basically this separates rendering from displaying (
 * so we can setup the modal without actually showing
 * it) and adds show/hide functions
 *
 * @type {Backbone.View}
 */
module.exports = ScreenBase.extend({
  initialize () {
    ScreenBase.prototype.initialize.apply(
      this,
      arguments
    );
  },
  render () {
    if (this.$el) {
      this.$el.remove();
      delete this.$el;
    }
    
    this.setElement(
      this.template({
        screenIndex: this.screenIndex,
        screenType: this.screenType,
        screenName: this.screenName,
        language: this.store.meta.get('language'),
        helpers: this.templateHelpers,
        responses: this.store.responses.get(
          this.screenName
        ),
        ...this.templateOptions
      })
    );
    
    this.$el.hide().appendTo(
      this.$parent
    );
    
    if (isFunction(this.postRender)) {
      this.postRender();
    }
  },
  show (goTo) {
    if (isFunction(this.preShow)) {
      this.preShow(goTo);
    }
    
    this.$el.fadeIn(() => {
      if (isFunction(this.postShow)) {
        this.postShow(goTo);
      }
      
      this.systemEvents.once({
        'modals:close': this.hide
      }, this);
    });
  },
  hide () {
    this.systemEvents.off('modals:close', null, this);
    this.$el.fadeOut();
  }
});
