const { View } = require('backbone');
const $ = require('jquery');
const store = require('../store');
const systemEvents = require('../util/SystemEvents');

View.$ = $;

const $body = $('body');
const $video = $('<video />').attr({
  id: 'videoShell',
  width: '1024',
  height: '748',
  playsinline: ''
}).append([
  $('<source />').attr({
    src: ''
  })
]).hide().appendTo($body);

/**
 * Base component. It contains the following:
 *
 *  - template: default template to render (should be overridden)
 *  - store: a reference to our data store
 *  - systemEvents: a reference to our system events registry
 *  - destroy: a method to destroy this component
 * @type {[type]}
 */
module.exports = View.extend({
  template: require('../templates/ViewBase.pug'),
  store: store,
  systemEvents: systemEvents,
  $video: $video,
  $source: $video.find('source'),
  video: $video[0],
  initialize (opts) {},
  render () {
    this.setElement(
      this.template()
    );
    
    this.$el.appendTo(
      this.$parent
    );
  },
  destroy () {
    this.$el.remove();
  }
})
