const { Nav1 } = require('@i18n');
const Base = require('./Base');

require('@assets/css/navigation-controls.css');

module.exports = Base.extend({
  template: require('../templates/NavigationControls.pug'),
  events: {
    'click .js-prev': 'prevScreen',
    'click .js-next': 'nextScreen'
  },
  initialize (opts) {
    this.$parent = opts.$parent;
    this.router = opts.router;
    
    // system events to listen for
    this.systemEvents.on({
      'next:enable': this.enableNext,
      'next:disable': this.disableNext,
      'next:hide': this.hideNext,
      'next:show': this.showNext,
      'violator:show': this.showViolator,
      'violator:hide': this.hideViolator,
      'language:change': this.languageChanged
    }, this);
    
    this.render();
    
    this.languageChanged();
  },
  render () {
    let language = this.store.meta.get('language');
    
    this.setElement(
      this.template({
        back: Nav1[language]['back.8'],
        next: Nav1[language]['next.9'],
        violator: Nav1[language]['violator.7']
      })
    );
    
    this.$next = this.$('.js-next');
    this.$summary = this.$('.js-summary');
    this.$nextLabel = this.$('.js-nextLabel');
    this.$backLabel = this.$('.js-backLabel');
    this.$summaryLabel = this.$('.js-summaryLabel');
    this.$violator = this.$('.js-violator');
    this.$violatorLabel = this.$('.js-violatorLabel');
    
    this.$el.appendTo(
      this.$parent
    );
  },
  languageChanged () {
    let language = this.store.meta.get('language');
    
    this.$nextLabel.text(
      Nav1[language]['next.9']
    );
    this.$backLabel.text(
      Nav1[language]['back.8']
    );
    this.$summaryLabel.text(
      Nav1[language]['summary.10']
    );
    this.$violatorLabel.text(
      Nav1[language]['violator.7']
    );
  },
  disableNext () {
    this.$next.attr({
      disabled: 'disabled'
    });
  },
  enableNext () {
    this.$next.removeAttr('disabled');
  },
  hideNext () {
    this.$next.addClass('g-hidden');
    this.$summary.addClass('g-hidden');
  },
  showNext () {
    this.$next.removeClass('g-hidden');
    this.$summary.addClass('g-hidden');
  },
  showViolator () {
    this.$violator.removeClass('g-hidden');
  },
  hideViolator () {
    this.$violator.addClass('g-hidden');
  },
  nextScreen () {
    this.router.nextScreen();
  },
  prevScreen () {
    this.router.prevScreen();
  }
});
