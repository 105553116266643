const { isFunction, isArray, isUndefined } = require('underscore');
const Base = require('./Base');

module.exports = Base.extend({
  tite: 'No title',
  screenIndex: -1,
  screenType: 'base-screen',
  handlers: {},
  conditionalNext: false,
  template: require('../templates/ViewScreen.pug'),
  initialize (opts) {
    this.screenIndex = opts.screenIndex;
    this.screenName = opts.screenName;
    this.noNavigation = opts.noNavigation;
    this.noControls = opts.noControls;
    if (!isUndefined(opts.conditionalNext)) {
      this.conditionalNext = opts.conditionalNext;
    }
    this.$parent = opts.$parent;
    this.templateOptions = opts.templateOptions;
    this.templateOptionsEnglish = opts.templateOptionsEnglish;
    this.router = opts.router;
    this.modals = opts.modals;
    this.handlers = Object.assign(
      this.handlers,
      opts.handlers
    );
    this.videoSource = opts.videoSource;
    this.noTransition = opts.noTransition;
    this.doPlay = opts.doPlay;
    
    if (isArray(this.modals)) {
      this.modals.forEach((modal) => modal.render());
    }
    
    // just always fire this (if we have controls on the screen),
    // for the most part, it will just keep the next button visible
    if (!opts.noControls) {
      if (opts.nextSummary) {
        this.systemEvents.trigger('next:summary', true);
      } else {
        this.systemEvents.trigger('next:summary', false);
      }
      
      if (opts.hideNext) {
        this.systemEvents.trigger('next:hide');
      } else {
        this.systemEvents.trigger('next:show');
      }
    }
    
    if (this.store.meta.get('summaryReached') &&
        !opts.hideViolator) {
      this.systemEvents.trigger('violator:show');
    } else {
      this.systemEvents.trigger('violator:hide');
    }
  },
  render () {
    this.setElement(
      this.template({
        screenIndex: this.screenIndex,
        screenType: this.screenType,
        screenName: this.screenName,
        language: this.store.meta.get('language'),
        studyId: this.store.meta.get('studyId'),
        responses: this.store.responses.get(
          this.screenName
        ),
        helpers: this.templateHelpers,
        ...this.templateOptions
      })
    );
    
    this.$el.on('click', '.js-term', () => {});
    
    if (this.noTransition) {
      this.$el.appendTo(
        this.$parent
      );
      if (isFunction(this.postRender)) {
        this.postRender();
      }
    } else {
      this.$el.hide().appendTo(
        this.$parent
      ).fadeIn(() => {
        if (isFunction(this.postRender)) {
          this.postRender();
        }
      });
    }
    
    if (isFunction(this.renderExtend)) {
      this.renderExtend();
    }
  },
  enableNextMaybe () {},
  unload () {
    this.systemEvents.off(null, null, this);
    this.destroyModals();
  },
  transition (done) {
    this.unload();
    this.$el.fadeOut('fast', () => {
      this.destroy();
      done();
    });
  },
  destroyModals () {
    // cleanup any modals that might have been created.
    if (isArray(this.modals)) {
      this.modals.forEach((modal) => {
        modal.destroy();
      });
    }
  }
})
