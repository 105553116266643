const axios = require('axios');
const store = require('../store');

/**
 * Saves notes data to the database.
 *
 * @return {Promise} a promise for the note save request
 */
module.exports = function () {
  let studyId = store.meta.get('studyId');
  
  return axios.post('/api/save/notes.php', store.notes.all().map(f => {
    f.studyId = studyId;
    return f;
  })).then((resp) => {
    // if response code is not 2xx, then something bad happened
    if (Math.floor(resp.status / 100) !== 2) {
      throw `Notes request returned status code ${xhr.status}`;
    }
    
    return true;
  }).catch((error) => {
    console.error('Notes request failed');
    
    return false;
  })
}
