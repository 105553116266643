module.exports = {
  screen: 'C12',
  section: 'C',
  view: 'screen-full-text',
  classes: [
    'fullText3'
  ],
  screenKeys: [
    'title.1',
    'body.2'
  ],
  modals: [
    require('./C11.js')
  ]
};
