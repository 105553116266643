const ScreenBase = require('./ScreenBase');
const store = require('../store');

require('@assets/css/screen-backup.css');

module.exports = ScreenBase.extend({
  screenType: 'screen-backup',
  template: require('@templates/ScreenBackup.pug'),
  events: {
    'click .js-export': 'doExport',
    'click .js-home': 'home'
  },
  render (success) {
    store.getAllBackups((err, backups) => {
      let backupJSON = JSON.stringify(backups);
      
      this.setElement(this.template({
        exportLink: `data:application/json;charset=utf-8, ${encodeURIComponent(backupJSON)}`
      }));
      
      this.$el.appendTo(
        this.$parent
      );
      
      this.$export = this.$('js-export');
    });
  },
  home () {
    this.router.goTo(0);
  },
  doExport (ev) {
    ev.stopPropagation();
  }
})
