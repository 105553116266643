module.exports = {
  screen: 'D6',
  section: 'D',
  view: 'screen-radio',
  screenKeys: [
    'title.1',
    'question.2',
    'option.3',
    'option.5',
    'option.7',
    'option.9',
    'option.11',
    'option.13',
    'footnote.15'
  ]
};
