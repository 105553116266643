const $ = require('jquery');
const { isFunction, isUndefined } = require('underscore');
const ScreenBase = require('./ScreenBase');
const translations = require('@i18n');
const NotesFeedbackConf = require('../screens/configs/NotesFeedback');
const prepTempOptions = require('../util/prepTempOptions');

require('@assets/css/notes-feedback-panel.css');

module.exports = ScreenBase.extend({
  template: require('@templates/NotesFeedbackPanel.pug'),
  noteTemplate: require('@templates/NotesFeedbackPanelNote.pug'),
  screenType: 'notes-feedback-panel',
  events: {
    'click .js-openFeedback': 'openFeedback',
    'click .js-closeFeedback': 'closeFeedback',
    'click .js-openNotes': 'openNotes',
    'click .js-closeNotes': 'closeNotes',
    
    // feedback specific events
    'click .js-send': 'recordFeedbackNotes',
    'click :radio': 'recordHelpful',
    'keyup .js-feedbackNotes': 'feedbackTyped',
    
    // notes specific events
    'click .js-deleteNote': 'deleteNote',
    'click .js-addNote': 'addNote',
    'keyup .js-noteText': 'setAddState'
  },
  initialize () {
    ScreenBase.prototype.initialize.apply(
      this,
      arguments
    );
    
    this.systemEvents.on({
      'panel:open': this.openPanel,
      'panel:close': this.closePanel,
      'panel:hide': this.hide,
      'panel:show': this.show,
      'notes:open': this.openNotes,
      'feedback:open': this.openFeedback
    }, this);
  },
  render (currentScreen, hide) {
    let language, templateOptions;
    
    if (this.$el) {
      this.$el.remove();
      delete this.$el;
    }
    
    if (hide) {
      return;
    }
    
    this.currentScreen = currentScreen;
    
    language = this.store.meta.get('language');
    templateOptions = prepTempOptions(
      NotesFeedbackConf,
      translations,
      language,
      this.store
    );
    
    this.setElement(
      this.template({
        screenType: this.screenType,
        language: language,
        helpers: this.templateHelpers,
        responses: this.store.feedback.get(currentScreen),
        notes: this.store.notes.all(),
        helpers: {
          buttonDisabled: () => {
            let { helpful, notes } = this.store.feedback.get(
              currentScreen
            );
            
            return (
              isUndefined(helpful) &&
              isUndefined(notes)
            );
          }
        },
        ...templateOptions
      })
    );
    
    this.$feedbackNotes = this.$('.js-feedbackNotes');
    this.$feedbackContent = this.$('.js-feedbackContent');
    this.$feedbackButton =  this.$('.js-feedbackButton');
    this.$sendButton = this.$('.js-send');
    this.$feedbackSent = this.$('.js-feedbackSent');

    this.$notesContent = this.$('.js-notesContent');
    this.$notesButton = this.$('.js-notesButton');
    this.$noteText = this.$('.js-noteText');
    this.$addNote = this.$('.js-addNote');
    this.$notes = this.$('.js-notes');
    this.$notesPlaceholder = this.$('.js-notesPlaceholder');
    
    this.$el.appendTo(
      this.$parent
    );
  },
  openPanel () {
    if (!this.$el) {
      return;
    }
    
    this.$el.addClass('open');
    this.$el.removeClass('closed');
  },
  closePanel () {
    if (!this.$el) {
      return;
    }
    
    this.$el.removeClass('open');
    this.$el.addClass('closed');
    
    this.$el.one('transitionend', () => {
      if (this.$el.hasClass('closed')) {
        // close feedback
        this.$feedbackButton.removeClass('js-closeFeedback');
        this.$feedbackButton.addClass('js-openFeedback feedbackClosed');
        
        // close notes
        this.$notesButton.removeClass('js-closeNotes');
        this.$notesButton.addClass('js-openNotes notesClosed');
      }
    });
  },
  openFeedback () {
    if (!this.$el) {
      return;
    }
    
    this.$feedbackButton.removeClass('js-openFeedback feedbackClosed');
    this.$feedbackContent.removeClass('g-hidden');
    this.$feedbackButton.addClass('js-closeFeedback');
    
    this.$notesButton.removeClass('js-closeNotes');
    this.$notesContent.addClass('g-hidden');
    this.$notesButton.addClass('js-openNotes notesClosed');
    
    this.openPanel();
  },
  closeFeedback (ev) {
    if (!this.$el) {
      return;
    }
    
    this.closePanel();
  },
  openNotes () {
    if (!this.$el) {
      return;
    }
    
    this.$notesButton.removeClass('js-openNotes notesClosed');
    this.$notesContent.removeClass('g-hidden');
    this.$notesButton.addClass('js-closeNotes');
    if (this.store.notes.all().length !== 0) {
      this.$notesPlaceholder.addClass('g-hidden');
    }

    this.$feedbackButton.removeClass('js-closeFeedback');
    this.$feedbackContent.addClass('g-hidden');
    this.$feedbackButton.addClass('js-openFeedback feedbackClosed');
    
    this.refreshNotes();
    this.openPanel();
  },
  closeNotes (ev) {
    if (!this.$el) {
      return;
    }
    
    this.closePanel();
  },
  hide () {
    if (this.$el) {
      this.$el.addClass('g-hidden');
    }
  },
  show () {
    if (this.$el) {
      this.$el.removeClass('g-hidden');
    }
  },
  recordFeedbackNotes (ev) {
    this.store.feedback.set(
      this.currentScreen,
      'text',
      this.$feedbackNotes.val()
    );
    
    this.$feedbackNotes.val('');
    this.$feedbackSent.removeClass('g-hidden');
    this.setSendState();
  },
  recordHelpful (ev) {
    let $radio = this.$(':checked');
    let value = $radio.val();
    let name = $radio.attr('name');
    
    this.$('.checked').removeClass('checked');
    $radio.closest('label').addClass('checked');
    
    this.store.feedback.set(
      this.currentScreen,
      name,
      value
    );
  },
  addNote () {
    this.store.notes.add(
      this.$noteText.val(),
      this.currentScreen
    );
    
    this.$noteText.val('');
    this.refreshNotes();
    this.setAddState();
    this.$notesPlaceholder.addClass('g-hidden');
  },
  deleteNote (ev) {
    let $el = $(ev.currentTarget);
    let id = $el.closest('.js-note').data('id');
    
    ev.stopPropagation();
    ev.preventDefault();
    
    this.store.notes.remove(id);
    if (this.store.notes.all().length === 0) {
      this.$notesPlaceholder.removeClass('g-hidden');
    }
    this.refreshNotes();
  },
  setAddState () {
    if (this.$noteText.val().trim().length) {
      this.$addNote.removeAttr('disabled');
    } else {
      this.$addNote.attr({
        disabled: 'disabled'
      });
    }
  },
  feedbackTyped () {
    this.$feedbackSent.addClass('g-hidden');
    this.setSendState();
  },
  setSendState () {
    if (this.$feedbackNotes.val().trim().length) {
      this.$sendButton.removeAttr('disabled');
    } else {
      this.$sendButton.attr({
        disabled: 'disabled'
      });
    }
  },
  refreshNotes () {
    this.systemEvents.trigger('notes:refresh');
    
    this.$notes.empty().append(
      this.store.notes.all().map(note => {
        return $(
          this.noteTemplate(note)
        );
      })
    );
  }
});
