const { isArray } = require('underscore');
const ScreenBaseCheckbox = require('./ScreenBaseCheckbox');

require('@assets/css/screen-checkboxes.css');

module.exports = ScreenBaseCheckbox.extend({
  template: require('@templates/ScreenCheckboxes.pug'),
  screenType: 'screen-checkboxes',
  events: {
    'change :checkbox': 'addChecked',
    'click .js-addAnotherReason': 'addAnotherReason'
  },
  templateHelpers: {
    isChecked (val, response) {
      if (isArray(response)) {
        return response.findIndex(resp => {
          return resp == val;
        }) > -1;
      }
      
      return val == response;
    },
    boxes () {
      return {
        0: [{
          cls: 'checkboxsinglelinetextcopy14'
        }, {
          cls: 'checkboxsinglelinetextcopy16',
          multi: true
        }, {
          cls: 'checkboxsinglelinetextcopy18'
        }, {
          cls: 'checkboxsinglelinetextcopy19'
        }, {
          cls: 'checkboxsinglelinetextcopy20'
        }],
       1: [{
         cls: 'checkboxsinglelinetextcopy14',
         multi: true
       }, {
         cls: 'checkboxsinglelinetextcopy18',
         multi: true
       }],
       2: [{
         cls: 'checkboxsinglelinetextcopy14'
       }, {
         cls: 'checkboxsinglelinetextcopy18',
         multi: true
       }, {
         cls: 'checkboxsinglelinetextcopy21'
       }],
       3: [{
         cls: 'checkboxsinglelinetextcopy14'
       }, {
         cls: 'checkboxsinglelinetextcopy16'
       }, {
         cls: 'checkboxsinglelinetextcopy18',
         multi: true
       }],
       4: [{
         cls: 'checkboxsinglelinetextcopy14',
         multi: true
       }, {
         cls: 'checkboxsinglelinetextcopy18',
         multi: true
       }, {
         cls: 'checkboxsinglelinetextcopy21',
         multi: true
       }, {
         cls: 'checkboxsinglelinetextcopy22',
         multi: true
       }, {
         cls: 'checkboxsinglelinetextcopy23',
         multi: true
       }]
      }
    }
  },
  postRender () {
    // stuff to display the others count
    this.$othersGroup = this.$('.js-othersGroup');
    this.$othersCount = this.$('.js-othersCount');
    
    // listen for the modal closing so we can update
    // th others count
    this.modals[0].on('others:closed',
      this.updateOthersCount,
      this
    );
    
    this.updateOthersCount();
  },
  addAnotherReason () {
    this.modals[0].show();
  },
  updateOthersCount () {
    let others = this.store.responses.get('D3-others');
    let count = [
      others.reason1,
      others.reason2,
      others.reason3
    ].filter(r => (r || '').trim().length).length;
    
    if (count > 0) {
      this.$othersCount.text(count);
      this.$othersGroup.removeClass('g-hidden');
    } else {
      this.$othersCount.text('');
      this.$othersGroup.addClass('g-hidden');
    }
  },
  unload () {
    if (isArray(this.modals)) {
      this.modals.forEach(modal => {
        modal.destroy();
      })
    }
  }
});
