const { isUndefined, isFunction } = require('underscore');

/**
 * Checks the supplied configuiration (conf) for a
 * a specified precondition (key).
 *
 * @param  {Object} conf the screen configuration to check for
 * the specified precondition
 * @param  {String} key  the key of the screen data item to check
 * for a precondition
 * @return {Boolean}     true if the config has a precondition, false
 * otherwise
 */
module.exports = (conf, key) => {
  return (
    !isUndefined(conf.preConditions) &&
    isFunction(conf.preConditions[key])
  );
}
