module.exports = {
  screen: 'D10',
  section: 'D',
  view: 'screen-likert',
  screenKeys: [
    'question.1',
    'option.2',
    'option.3',
    'option.4'
  ]
};
