const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-feedback-panel-explainer.css');

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenFeedbackPanelExplainer.pug'),
  screenType: 'screen-feedback-panel-explainer',
  postRender () {
    this.systemEvents.trigger('feedback:open');
  }
});
