const $ = require('jquery');
const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-notes-last-chance.css');

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenNotesLastChance.pug'),
  screenType: 'screen-notes-last-chance',
  postRender () {
    this.systemEvents.trigger('notes:open');
    
    // change out the button
    $('.js-next').addClass('g-hidden');
    $('.js-summary').removeClass('g-hidden');
  }
});
