module.exports =   {
  screen: 'A1',
  section: 'A',
  view: 'screen-title-with-buttons',
  noControls: true,
  noNavigation: true,
  screenKeys: [
    'title.1',
    'subtitle.2',
    'button-1.3',
    'button-2.4',
    'footnote.5'
  ],
  handlers: {
    button1 (view) {
      view.router.nextScreen();
    },
    button2 (view) {
      let lang = view.store.meta.get('language');
      
      if (lang === 'en') {
        view.store.meta.set('language', 'es');
      } else {
        view.store.meta.set('language', 'en');
      }
      
      view.systemEvents.trigger('language:change',
        view.store.meta.get('language'),
        lang
      );
      
      view.router.reloadScreen();
    }
  }
};
