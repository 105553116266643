const $ = require('jquery');
const ScreenBaseModal = require('./ScreenBaseModal');

require('@assets/css/screen-add-other-reasons.css');

module.exports = ScreenBaseModal.extend({
  template: require('@templates/ScreenAddOtherReasons.pug'),
  screenType: 'screen-add-other-reasons',
  events: {
    'click .js-done': 'done',
    'change :text': 'addReason'
  },
  addReason (ev) {
    let $el = $(ev.currentTarget);
    let name = $el.attr('name');
    let value = $el.val();
    
    this.store.responses.set(
      this.screenName,
      name,
      value
    );
  },
  done () {
    this.hide();
    this.trigger('others:closed');
  }
});
