const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-notes-panel-explainer.css');

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenNotesPanelExplainer.pug'),
  screenType: 'screen-notes-panel-explainer',
  postRender () {
    this.systemEvents.trigger('notes:open');
  }
});
