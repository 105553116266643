const NOT_SURE = 2;

module.exports = {
  screen: 'E7',
  section: 'E',
  view: 'screen-checkbox-list',
  screenKeys: [
    'title.1',
    'body.2',
    'methods',
    'notInterested',
    'anotherMethod'
  ],
  conditionalShow (store) {
   let { answer } = store.responses.get('E3');
   
   return answer == NOT_SURE;
  }
};
