const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-video-intro.css');

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenVideoIntro.pug'),
  screenType: 'screen-video-intro',
  events: {
    'click .js-play': 'playVideo'
  },
  postRender () {
    
    this.$source.attr({
      src: this.videoSource
    });
  },
  prepVideo () {
    this.video.load();
    this.$video.data('videostarted', true);
    this.$video.on('canplay', () => {
      this.video.play().catch(err => {
        console.error(err);
      });
    });
  },
  preNext () {
    this.prepVideo();
  },
  playVideo () {
    this.router.nextScreen();
  }
});
