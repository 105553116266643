var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (body, button, helpers, responses, sections, title) {let boxes = helpers.boxes();
pug_mixins["checkbox"] = pug_interp = function(num, cls, section, multi){
var block = (this && this.block), attributes = (this && this.attributes) || {};
let meChecked = helpers.isChecked(section.reasons[num], responses.reason);
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["checkbox",cls,(meChecked ? 'checked' : '')], [false,true,true]), false, true)) + "\u003E\u003Cdiv class=\"touch\"\u003E \u003Cdiv class=\"outer\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"inner\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([(multi ? 'doublelinetexther' : 'singlelinetexther anima-valign-text-middle')], [true]), false, true)) + "\u003E" + (null == (pug_interp = section.reasons[num]) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cinput" + (" type=\"checkbox\" name=\"reason\""+pug.attr("value", section.reasons[num], true, true)+pug.attr("data-string", section.reasons[num], true, true)+pug.attr("checked", meChecked, true, true)) + "\u003E\u003C\u002Flabel\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"screenCheckboxes\"\u003E\u003Cdiv class=\"title\"\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"subtitle\"\u003E" + (null == (pug_interp = body) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"familysituation\"\u003E\u003Cdiv class=\"familysituation1\"\u003E" + (null == (pug_interp = sections[0].title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
// iterate boxes[0]
;(function(){
  var $$obj = boxes[0];
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[0], box.multi);
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[0], box.multi);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"relationshipfactors\"\u003E\u003Cdiv class=\"relationshipfactors1\"\u003E" + (null == (pug_interp = sections[1].title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
// iterate boxes[1]
;(function(){
  var $$obj = boxes[1];
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[1], box.multi);
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[1], box.multi);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"personalgoals\"\u003E\u003Cdiv class=\"personalgoals1\"\u003E" + (null == (pug_interp = sections[2].title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
// iterate boxes[2]
;(function(){
  var $$obj = boxes[2];
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[2], box.multi);
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[2], box.multi);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"lifecircumstances\"\u003E\u003Cdiv class=\"lifecircumstances1\"\u003E" + (null == (pug_interp = sections[3].title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
// iterate boxes[3]
;(function(){
  var $$obj = boxes[3];
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[3], box.multi);
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[3], box.multi);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"healthfactors\"\u003E\u003Cdiv class=\"healthfactors1\"\u003E" + (null == (pug_interp = sections[4].title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
// iterate boxes[4]
;(function(){
  var $$obj = boxes[4];
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[4], box.multi);
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var box = $$obj[i];
pug_mixins["checkbox"](i, box.cls, sections[4], box.multi);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"addreasonsbtn js-addAnotherReason\"\u003E\u003Cimg class=\"rectangle\" src=\"\u002Fimg\u002Fcheckboxes-rectangle@2x.png\"\u003E\u003Cdiv class=\"button anima-valign-text-middle\"\u003E" + (null == (pug_interp = button) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"group js-othersGroup g-hidden\"\u003E\u003Cimg class=\"oval\" src=\"\u002Fimg\u002Fcheckboxes-with-count-oval@2x.png\"\u003E\u003Cdiv class=\"a5 anima-valign-text-middle js-othersCount\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"body" in locals_for_with?locals_for_with.body:typeof body!=="undefined"?body:undefined,"button" in locals_for_with?locals_for_with.button:typeof button!=="undefined"?button:undefined,"helpers" in locals_for_with?locals_for_with.helpers:typeof helpers!=="undefined"?helpers:undefined,"responses" in locals_for_with?locals_for_with.responses:typeof responses!=="undefined"?responses:undefined,"sections" in locals_for_with?locals_for_with.sections:typeof sections!=="undefined"?sections:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;