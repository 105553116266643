const bcrypt = require('bcryptjs');

// master password to compare against
const MASTER_PASSWORD = '$2a$08$06tKqWzHxWypncXAZpyFQ.rx1I0ugap3ZzXpJTxmF0D7do0p/TBTe';

/**
 * Checks a string against the master password.
 *
 * @param  {String} password the password to check
 * @return {Boolean}         true if the password is valid,
 * false otherwise
 */
module.exports = (password) => {
  if (!password) {
    return false;
  }
  
  return bcrypt.compareSync(
    password,
    MASTER_PASSWORD
  );
}
