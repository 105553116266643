/**
 * views by screen type
 */
module.exports =  {
  'screen-add-other-reasons': require('../views/ScreenAddOtherReasons'),
  'screen-bad': require('../views/ScreenBad'),
  'screen-bottom-likert': require('../views/ScreenBottomLikert'),
  'screen-checkboxes': require('../views/ScreenCheckboxes'),
  'screen-checkbox-list': require('../views/ScreenCheckboxList'),
  'screen-comparison-table': require('../views/ScreenComparisonTable'),
  'screen-divided-text': require('../views/ScreenDividedText'),
  'screen-drag-drop': require('../views/ScreenDragDrop'),
  'screen-faq': require('../views/ScreenFaq'),
  'screen-feedback-panel-explainer': require('../views/ScreenFeedbackPanelExplainer'),
  'screen-full-text': require('../views/ScreenFullText'),
  'screen-internal-title': require('../views/ScreenInternalTitle'),
  'screen-likert': require('../views/ScreenLikert'),
  'screen-multiple-likert': require('../views/ScreenMultipleLikert'),
  'screen-multiple-likert-other': require('../views/ScreenMultipleLikertOther'),
  'screen-multiple-radio': require('../views/ScreenMultipleRadio'),
  'screen-notes-last-chance': require('../views/ScreenNotesLastChance'),
  'screen-notes-panel-explainer': require('../views/ScreenNotesPanelExplainer'),
  'screen-one-two-column': require('../views/ScreenOneTwoColumn'),
  'screen-options-table': require('../views/ScreenOptionsTable'),
  'screen-radio': require('../views/ScreenRadio'),
  'screen-small-title-text': require('../views/ScreenSmallTitleText'),
  'screen-study-id': require('../views/ScreenStudyId'),
  'screen-summary-report': require('../views/ScreenSummaryReport'),
  'screen-tabbed-modal': require('../views/ScreenTabbedModal'),
  'screen-text-over-checkboxes': require('../views/ScreenTextOverCheckboxes'),
  'screen-three-column': require('../views/ScreenThreeColumn'),
  'screen-title': require('../views/ScreenTitle'),
  'screen-title-full-text': require('../views/ScreenTitleFullText'),
  'screen-title-with-buttons': require('../views/ScreenTitleWithButtons'),
  'screen-two-column': require('../views/ScreenTwoColumn'),
  'screen-two-column-input': require('../views/ScreenTwoColumnInput'),
  'screen-two-column-sidebar': require('../views/ScreenTwoColumnSidebar'),
  'screen-video': require('../views/ScreenVideo'),
  'screen-video-intro': require('../views/ScreenVideoIntro')
}
