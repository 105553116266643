const $ = require('jquery');
const { isArray, isFunction } = require('underscore');
const translations = require('@i18n');
const views = require('./views');
const prepTempOptions = require('./prepTempOptions');

const $body = $('body');

/**
 * Proccesses the parameters for a screen given the screen's configuration
 * and, well, a bunch of other paramters...
 *
 * @param  {Object}  conf     screen configuration
 * @param  {String}  language the language we are currently using
 * @param  {Number}  index    screen index
 * @param  {Element} $parent  the parent for this screen (jQuery enhanced DOM
 * element)
 * @param  {Router}  router   our router (instance of Backbone.Router)
 * @param  {Object}  store    our client-side store
 * @return {Object}           an object of parameters to pass along to the
 * screens View
 */
module.exports = function process (conf, language, index, $parent, router, store) {
  let videoSource = (isFunction(conf.videoSource)
    ? conf.videoSource(language)
    : ''
  );
  return {
    screenIndex: index,
    $parent: $parent,
    screenName: conf.screen,
    noNavigation: conf.noNavigation,
    noControls: conf.noControls,
    noTransition: conf.noTransition,
    hideNext: conf.hideNext,
    nextSummary: conf.nextSummary,
    hideViolator: conf.hideViolator,
    doPlay: conf.doPlay,
    conditionalNext: conf.conditionalNext,
    templateOptions: prepTempOptions(
      conf,
      translations,
      language,
      store
    ),
    templateOptionsEnglish: prepTempOptions(
      conf,
      translations,
      'en',
      store
    ),
    handlers: conf.handlers,
    router: router,
    videoSource: videoSource,
    modals: (isArray(conf.modals)
      ? conf.modals.map((modalConf) => {
        if (!views[modalConf.view]) {
          return;
        }
        
        return new views[modalConf.view](
          process(
            modalConf,
            language,
            index,
            $body,
            router,
            store
          )
        );
      })
      : []
    )
  };
}
