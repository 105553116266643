const { isUndefined, chain } = require('underscore');

const FEEDBACK_DELIMITER = '|';

module.exports = {
  store: require('store'),
  
  /**
   * Sets a feedback response for a particular screen.
   *
   * @param {String}   screen id of the screen to to record this response for
   * @param {String}   key    the key of the response
   * @param {Whatever} value  the value to record
   */
  set (screen, key, value) {
    let feedback;
    
    if (!this.store.get('feedback')) {
      this.store.set('feedback', {});
    }
    
    feedback = this.store.get('feedback');
    
    if (isUndefined(feedback[screen])) {
      feedback[screen] = {
        text: []
      };
    }
    
    if (key === 'text') {
      feedback[screen].text.push(value);
    } else {
      feedback[screen][key] = value;
    }
    
    
    this.store.set('feedback', feedback);
  },
  
  /**
   * Grabs the feedback for a particular screen.
   *
   * @param  {String} screen screen to grab feedback for
   * @return {Array}         an array of the feedback for the screen
   */
  get (screen) {
    let feedback;
    
    if (!this.store.get('feedback')) {
      this.store.set('feedback', {});
    }
    
    feedback = this.store.get('feedback');
    
    return Object.assign(
      {},
      feedback[screen]
    );
  },
  
  /**
   * Get a JSON representation of this store.
   *
   * @return {String} JSON representation of this store
   */
  json () {
    return JSON.stringify(
      this.store.get('feedback')
    );
  },
  
  /**
   * Get all the feedback.
   *
   * @return {Array} an array of feedback information provided
   */
  all () {
    let feedback = this.store.get('feedback');
    
    return chain(feedback).map(({text, helpful}, screen) => {
      return {
        screen: screen,
        helpful: isUndefined(helpful) ? -1 : helpful,
        notes: text.join(FEEDBACK_DELIMITER)
      };
    }).value();
  },
  
  /**
   * Resets the feedback.
   */
  reset () {
    this.store.set('feedback', {});
  }
}
