const { isFunction } = require('underscore');
const screens = require('../screens');

/**
 * Given a current screen index, this function will return an
 * object indicating the current progress (per section).
 *
 * @param  {Number} screenIdx the screen index for the current screen
 * @return {Object}           an object of the form:
 *
 *  {
 *    <section>: {
 *      seen: <number>,
 *      total: <number>
 *    }
 *  }
 */
module.exports = (screenIdx) => {
  let foundCurrent = false;
  return screens.reduce((h, s, i) => {
    // if this section is a function, it means
    // we will need the store to determine
    // which format to render... we don't have
    // the store here and either way, we are
    // always going to render a single page,
    // in the same section...
    if (isFunction(s)) {
      s = s();
    }
    
    if (!h.perScreen[s.section]) {
      h.perScreen[s.section] = {
        seen: 0,
        total: 0
      };
    }
    
    if (i < screenIdx) {
      h.perScreen[s.section].seen++;
    } else if (!foundCurrent) {
      foundCurrent = true;
      h.currentSection = s.section;
    }
    
    h.perScreen[s.section].total++;
    
    return h;
  }, {
    total: screens.length,
    current: screenIdx,
    currentSection: 'A',
    perScreen: {}
  });
}
