const $ = require('jquery');
const Base = require('./Base');
const methods = require('@i18n/shared/methods.json');
const processText = require('../util/processText');

require('@assets/css/popover-method.css');

const $body = $('body');

module.exports = Base.extend({
  template: require('@templates/PopoverMethod.pug'),
  overlay: require('@templates/PopoverMethodOverlay.pug'),
  events: {
    'click .js-close': 'hide'
  },
  initialize (opts) {
    this.$parent = opts.$parent;
  },
  render (methodIdx, column, $cell) {
    let language = this.store.meta.get('language');
    let method = this.retrieveMethod(methodIdx);
    
    this.$cell = $cell;
    
    if (this.$el) {
      this.$el.remove();
    }

    this.setElement(
      this.template({
        method: {
          name: processText(language, method.longName, method, {}, true),
          description: processText(language, method.description),
          image: method.image
        },
        column: column
      })
    );
    
    $body.addClass('doBlur');
    
    if (this.$cell) {
      this.$cell.closest('.methodCell').addClass('popped');
    }
    
    this.$overlay = $(this.overlay()).click((ev) => {
     this.hide(ev);
    }).appendTo(
      $body
    );
    
    this.$el.appendTo(
      this.$parent
    );
  },
  retrieveMethod (idx) {
    let language = this.store.meta.get('language');
    return methods[language][idx];
  },
  overlayClicked (ev) {
    let $el = $(ev.target);
    
    if (!$el.closest('.methodPopover').length) {
      this.hide();
    }
  },
  hide () {
    $body.removeClass('doBlur');
    
    if (this.$cell) {
      this.$cell.closest('.methodCell').removeClass('popped');
      delete this.$cell;
    }
    
    // the overlay only gets built when we show the
    // modal, so this may not exist, yet...
    if (this.$overlay) {
      this.$overlay.remove();
    }
    
    // close any potentially open term popovers
    this.systemEvents.trigger('popover:hide');
    
    this.$el.remove();
  },
  unload () {
    this.systemEvents.off(null, null, this);
  }
});
