module.exports = {
  screen: 'C5',
  section: 'C',
  view: 'screen-options-table',
  modals: [
    require('./C11.js')
  ],
  screenKeys: [
    'title.1',
    'subtitle.2',
    'subtitle.3',
    'highlight.4',
    'highlight.5',
    'highlight.6',
    'highlight.7',
    'highlight.8',
    'header.9',
    'header.10',
    'header.11',
    'cell.12',
    'subcell.13',
    'cell.14',
    'subcell.15',
    'cell.16',
    'subcell.17',
    'cell.18',
    'subcell.19',
    'cell.20',
    'subcell.21',
    'cell.22',
    'subcell.23',
    'cell.24',
    'subcell.25',
    'cell.26',
    'subcell.27',
    'cell.28',
    'subcell.29',
    'cell.30',
    'subcell.31',
    'cell.32',
    'subcell.33',
    'cell.34',
    'subcell.35',
    'button.36'
  ]
};
