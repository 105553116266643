module.exports = {
  screen: 'E2',
  section: 'E',
  view: 'screen-multiple-likert-other',
  screenKeys: [
    'title.1',
    'body.2',
    'label.3',
    'label.4',
    'label.5',
    'question.6',
    'question.7',
    'question.8',
    'question.9',
    'footnote.10'
  ]
};
