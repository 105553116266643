var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (body, classes, intro, screenName, title, youSaid) {pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["screenFullText",classes.join(' ')], [false,true]), false, true)) + "\u003E";
if (title) {
pug_html = pug_html + "\u003Cdiv class=\"title\"\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
if (intro) {
pug_html = pug_html + "\u003Cdiv class=\"intro\"\u003E" + (null == (pug_interp = intro) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
if (youSaid && youSaid.length) {
pug_html = pug_html + "\u003Cdiv class=\"youSaid\"\u003E\u003Cul\u003E";
// iterate youSaid
;(function(){
  var $$obj = youSaid;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var said = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E\u003Cspan class=\"said\"\u003E" + (null == (pug_interp = said) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var said = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E\u003Cspan class=\"said\"\u003E" + (null == (pug_interp = said) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E";
}
if (body) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["body",`screenName${screenName}`], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = body) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"body" in locals_for_with?locals_for_with.body:typeof body!=="undefined"?body:undefined,"classes" in locals_for_with?locals_for_with.classes:typeof classes!=="undefined"?classes:undefined,"intro" in locals_for_with?locals_for_with.intro:typeof intro!=="undefined"?intro:undefined,"screenName" in locals_for_with?locals_for_with.screenName:typeof screenName!=="undefined"?screenName:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"youSaid" in locals_for_with?locals_for_with.youSaid:typeof youSaid!=="undefined"?youSaid:undefined));;return pug_html;};
module.exports = template;