module.exports = {
  screen: 'B18',
  section: 'B',
  view: 'screen-tabbed-modal',
  screenKeys: [
    'tab.1',
    'tab.subtitle.2',
    'body.3',
    'sibebar.title.4',
    'sidebar.body.5',
    'sidebar.image.6',
    'footer.7',
    'tab.8',
    'tab.subtitle.9',
    'body.10',
    'sibebar.title.11',
    'sidebar.body.12',
    'sidebar.image.13',
    'footer.14',
    'tab.15',
    'tab.subtitle.16',
    'body.17',
    'sibebar.title.18',
    'sidebar.body.19',
    'sidebar.image.20',
    'footer.21'    
  ]
};
