const $ = require('jquery');
const Popover = require('./Popover');
const terms = require('../glossary/terms.json');

const POPOVER_HEIGHT = 185;
const POPOVER_WIDTH = 335;
const SCREEN_HEIGHT = 768;
const SCREEN_WIDTH = 1024;

const MARGIN = 15;

const $body = $('body');

module.exports = Popover.extend({
  show ($for) {
    let term = $for.data('term');
    let language = this.store.meta.get('language');
    let { definition, display } = terms[language][term];
    let width = $for.width();
    let bodyOffset = $body.offset();
    let { left, top } = $for.offset();
    
    left = left - bodyOffset.left;
    top = top - bodyOffset.top;
    
    // vertical positioning
    if (top + POPOVER_HEIGHT > SCREEN_HEIGHT) {
      top -= POPOVER_HEIGHT;
    }
    
    // horizontal positioning
    if ((left + width + POPOVER_WIDTH) > SCREEN_WIDTH) {
      left -= (POPOVER_WIDTH + MARGIN);
    } else {
      left += (width + MARGIN);
    }

    this.render(
      display,
      definition,
      top,
      left
    );
  }
});
