module.exports = {
  screen: 'E4',
  section: 'E',
  view: 'screen-full-text',
  classes: [
    'fullText3'
  ],
  screenKeys: [
    'title.1',
    'body.2'
  ]
};
