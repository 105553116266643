const $ = require('jquery');
const Base = require('./Base');
const Backbone = require('backbone');
const Router = require('../Router');
const Navigation = require('./Navigation');
const NavigationControls = require('./NavigationControls');
const NotesFeedbackPanel = require('./NotesFeedbackPanel');
const ScreenReset = require('./ScreenReset');
const ScreenBackup = require('./ScreenBackup');

const $body = $('body');

module.exports = Base.extend({
  template: require('../templates/LayoutMain.pug'),
  initialize (opts) {
    this.$parent = opts.$parent;
    this.popover = opts.popover;
    
    this.systemEvents.on({
      'language:change': this.addLanguageClass
    }, this);
    
    this.render();
  },
  render () {
    let language = this.store.meta.get('language');
    
    this.setElement(
      this.template()
    );
    
    this.$content = this.$('.js-screenContent');
    
    this.router = new Router({
      layout: this,
      store: this.store,
      systemEvents: this.systemEvents
    });
    
    this.navigation = new Navigation({
      $parent: this.$('.js-navigationContent'),
      router: this.router
    });
    
    this.navigationControls = new NavigationControls({
      $parent: this.$('.js-navigationControls'),
      router: this.router
    });
    
    this.notesFeedbackPanel = new NotesFeedbackPanel({
      $parent: $body
    });
    
    this.resetScreen = new ScreenReset({
      $parent: this.$content,
      screenName: 'reset',
      noNavigation: true,
      noControls: true,
      router: this.router
    });
    
    this.backupScreen = new ScreenBackup({
      $parent: this.$content,
      screenName: 'backups',
      noNavigation: true,
      noControls: true,
      router: this.router
    });
    
    Backbone.history.start();
    
    $body.addClass(`language-${language}`);
    
    this.$el.appendTo(
      this.$parent
    );
  },
  addLanguageClass (newLang, prevLang) {
    $body.removeClass(`language-${prevLang}`)
         .addClass(`language-${newLang}`);
  },
  hasCurrentScreen () {
    return !!this.currentScreen;
  },
  showResetScreen (success) {
    this.$content.empty();
    
    if (this.notesFeedbackPanel) {
      this.notesFeedbackPanel.closePanel();
      this.notesFeedbackPanel.hide();
    }
    
    if (this.hasCurrentScreen()) {
      return this.currentScreen.transition(() => {
        $body.addClass('noNavigation');
        $body.addClass('noControls');
        this.currentScreen = this.resetScreen;
        this.resetScreen.render();
      });
    } else {
      $body.addClass('noNavigation');
      $body.addClass('noControls');
      this.currentScreen = this.resetScreen;
      this.resetScreen.render();
    }
  },
  showBackups () {
    this.$content.empty();
    
    if (this.currenScreen) {
      this.currentScreen.transition(() => {
        $body.addClass('noNavigation');
        $body.addClass('noControls');
        this.currentScreen = this.backupScreen;
        this.backupScreen.render();
      });
    } else {
      $body.addClass('noNavigation');
      $body.addClass('noControls');
      this.currentScreen = this.backupScreen;
      this.backupScreen.render();
    }
  },
  showScreen (screenIdx) {
    let nextScreen = this.getScreen(
      screenIdx,
      this.$content,
      this.router,
      this.store
    );
    
    // hide any open popovers
    this.popover.hide();
    
    if (this.hasCurrentScreen() &&
        !this.noTransition) {
      return this.currentScreen.transition(() => {
        this.prepNextScreen(
          screenIdx,
          nextScreen
        );
      });
    }
    
    this.prepNextScreen(
      screenIdx,
      nextScreen
    );
  },
  prepNextScreen (screenIdx, nextScreen) {
    this.currentScreen = nextScreen;
    this.currentScreen.render();
    
    this.notesFeedbackPanel.render(
      nextScreen.screenName,
      (nextScreen.noControls || nextScreen.noFeedback)
    );
    
    if (this.currentScreen.conditionalNext) {
      this.systemEvents.trigger('next:disable');
      this.currentScreen.enableNextMaybe();
    } else {
      this.systemEvents.trigger('next:enable');
    }
    
    if (this.currentScreen.noNavigation) {
      $body.addClass('noNavigation');
    } else {
      $body.removeClass('noNavigation');
    }
    
    if (this.currentScreen.noControls) {
      $body.addClass('noControls');
    } else {
      $body.removeClass('noControls');
    }
    
    this.systemEvents.trigger(
      'screen:change',
      screenIdx
    );
  },
  getScreen: require('../util/getScreen')
})
