const { Router } = require('backbone');
const { isNull, isUndefined, isFunction } = require('underscore');
const progress = require('./util/progress');
const screens = require('./screens');
const saveUserData = require('./util/saveUserData');

const FORWARD = 1;

module.exports = Router.extend({
  routes: {
    'backups': 'showBackups',
    '*idx': 'openScreen'
  },
  initialize (opts) {
    this.currentScreenIdx = 0;
    this.currentScreen = null;
    
    this.store = opts.store;
    this.layout = opts.layout;
    this.systemEvents = opts.systemEvents;
    
    this.systemEvents.on({
      'link:screen': this.jumpToScreen
    }, this);
  },
  jumpToScreen (screen) {
    let idx = screens.findIndex(conf => {
      return (
        conf.screen &&
        (
          conf.screen.toLowerCase() ===
          screen.toLowerCase()
        )
      );
    });
    
    if (idx > -1) {
     this.goTo(idx);
   } else {
     console.error(`couldn't find ${screen}`);
   }
  },
  openScreen (idx) {
    let furthest, saved;
    
    furthest = this.store.meta.get('furthest');
    
    if (isNull(idx) ||
        isUndefined(idx)) {
      idx = 1;
    } else if (idx < 0) {
      if (idx == -2) {
        return saveUserData().then((responses, feedback) => {
          // on success, save a backup
          this.store.backup((err) => {
            let previousLanguage = this.store.meta.get('language');
            
            if (err) {
              console.error('backup failed...');
            }
            
            this.store.reset();
            this.systemEvents.trigger(
              'language:change',
              this.store.meta.get('language'),
              previousLanguage
            );
            return this.goTo(-1);
          });
        }).catch((err) => {
          this.store.meta.set('failedSave', true);
          return this.goTo(-1);
        });
      } else if (idx == -1) {
        return this.layout.showResetScreen();
      }
    }
    
    // only update this if we got further
    // than we had previously (i.e. don't
    // record backwards jumps)
    if (+idx > +furthest) {
      this.store.meta.set('furthest', +idx);
    }
    
    this.currentScreenIdx = idx;
    this.layout.showScreen(
      this.currentScreenIdx
    );
  },
  findNext (idx, direction) {
    let checker = (screen) => {
      if (isFunction(screen.conditionalShow)) {
        return screen.conditionalShow(this.store);
      }
      
      return true;
    };
    
    if (direction === FORWARD) {
      return idx + screens.slice(idx)
                          .findIndex(checker);
    } else {
      return idx - screens.slice(0, idx + 1)
                          .reverse()
                          .findIndex(checker);
    }
  },
  nextScreen () {
    let { currentScreen } = this.layout;
    
    if (currentScreen &&
        isFunction(currentScreen.preNext)) {
      this.layout.currentScreen.preNext();
    }
    
    this.currentScreenIdx = this.findNext(
      ++this.currentScreenIdx,
      1
    );
    window.location.hash = `#${this.currentScreenIdx}`;
  },
  prevScreen () {
    this.currentScreenIdx = this.findNext(
      --this.currentScreenIdx,
      -1
    );
    window.location.hash = `#${this.currentScreenIdx}`;
  },
  goTo (idx) {
    this.currentScreenIdx = idx;
    window.location.hash = `#${this.currentScreenIdx}`;
  },
  reloadScreen () {
    this.openScreen(this.currentScreenIdx);
  },
  showBackups () {
    this.layout.showBackups();
  }
});
