var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (anotherMethod, body, helpers, methods, notInterested, responses, title) {pug_mixins["checkbox"] = pug_interp = function(num, idx, method, col){
var block = (this && this.block), attributes = (this && this.attributes) || {};
let meChecked = helpers.isChecked(idx, responses.answer)
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["checkbox",`check${num % 3 + 1}`,(meChecked ? 'checked' : '')], [false,true,true]), false, true)+pug.attr("for", `checkbox${idx}`, true, true)) + "\u003E\u003Cdiv class=\"checkPortion\"\u003E\u003Cdiv class=\"box\"\u003E\u003Cdiv class=\"checkText anima-valign-text-middle\"\u003E" + (null == (pug_interp = method.name) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"touch\"\u003E\u003Cdiv class=\"outer\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"inner\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (pug.attr("id", `checkbox${idx}`, true, true)+" type=\"checkbox\" name=\"answer\""+pug.attr("value", idx, true, true)+pug.attr("data-string", method.name, true, true)+pug.attr("checked", meChecked, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"checkInfo\""+pug.attr("data-description", method.description, true, true)) + "\u003E\u003Cdiv" + (" class=\"icon24px js-openMethodPopover\""+pug.attr("data-method", idx, true, true)) + "\u003E\u003Cimg class=\"shape1\" src=\"\u002Fimg\u002Fcheckbox-list-shape-19@2x.png\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Flabel\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"screenCheckboxList\"\u003E\u003Cdiv class=\"title\"\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"body\"\u003E" + (null == (pug_interp = body) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"checkboxlist1\"\u003E\u003Cdiv class=\"row1 row\"\u003E";
pug_mixins["checkbox"](0, 1, methods[1]);
pug_mixins["checkbox"](1, 6, methods[6]);
pug_mixins["checkbox"](2, 10, methods[10]);
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"row2 row\"\u003E";
pug_mixins["checkbox"](3, 3, methods[3]);
pug_mixins["checkbox"](4, 7, methods[7]);
pug_mixins["checkbox"](5, 11, methods[11]);
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"row3 row\"\u003E";
pug_mixins["checkbox"](6, 4, methods[4]);
pug_mixins["checkbox"](7, 8, methods[8]);
pug_mixins["checkbox"](8, 12, methods[12]);
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"row4 row\"\u003E";
pug_mixins["checkbox"](9, 5, methods[5]);
pug_mixins["checkbox"](10, 9, methods[9]);
pug_mixins["checkbox"](11, 13, methods[13]);
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"checkboxlist2\"\u003E\u003Cdiv class=\"checkboxwithinfocopy17\"\u003E";
let anotherChecked = helpers.isChecked(true, responses.anotherMethod)
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["checkbox",(anotherChecked ? 'checked' : '')], [false,true]), false, true)+" for=\"anotherMethod\"") + "\u003E\u003Cdiv class=\"checkPortion\"\u003E\u003Cdiv class=\"box\"\u003E\u003Cdiv class=\"checkText anima-valign-text-middle\"\u003E" + (null == (pug_interp = anotherMethod) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"touch\"\u003E\u003Cdiv class=\"outer\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"inner\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (" id=\"anotherMethod\" type=\"checkbox\" name=\"anotherMethod\" value=\"anotherMethod\""+pug.attr("checked", anotherChecked, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Flabel\u003E\u003Cinput" + (" class=\"anotherMethodEntry\""+" id=\"anotherMethodInput\" type=\"text\" name=\"anotherMethodInput\" title=\"Enter Another Method\""+pug.attr("value", (anotherChecked ? responses.anotherMethodText : ''), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"checkboxwithinfocopy18\"\u003E";
let notInterestedChecked = helpers.isChecked(true, responses.notInterested)
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["checkbox",(notInterestedChecked ? 'checked' : '')], [false,true]), false, true)+" id=\"notInterestedText\" for=\"notInterested\"") + "\u003E\u003Cdiv class=\"checkPortion\"\u003E\u003Cdiv class=\"box\"\u003E\u003Cdiv class=\"checkText anima-valign-text-middle\"\u003E" + (null == (pug_interp = notInterested) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"touch\"\u003E\u003Cdiv class=\"outer\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"inner\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (" id=\"notInterested\" type=\"checkbox\" name=\"notInterested\" value=\"notInterested\""+pug.attr("checked", notInterestedChecked, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"anotherMethod" in locals_for_with?locals_for_with.anotherMethod:typeof anotherMethod!=="undefined"?anotherMethod:undefined,"body" in locals_for_with?locals_for_with.body:typeof body!=="undefined"?body:undefined,"helpers" in locals_for_with?locals_for_with.helpers:typeof helpers!=="undefined"?helpers:undefined,"methods" in locals_for_with?locals_for_with.methods:typeof methods!=="undefined"?methods:undefined,"notInterested" in locals_for_with?locals_for_with.notInterested:typeof notInterested!=="undefined"?notInterested:undefined,"responses" in locals_for_with?locals_for_with.responses:typeof responses!=="undefined"?responses:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;