const $ = require('jquery');
const { isArray, isUndefined } = require('underscore');
const ScreenBase = require('./ScreenBase');

/**
 * This view will serve as the base screen for any
 * checkbox related screens. It provides an event
 * handler and some template helpers.
 *
 * @type {Backbone.View}
 */
module.exports = ScreenBase.extend({
  templateHelpers: {
    isChecked (num, response) {
      if (isArray(response)) {
        return response.findIndex(resp => {
          return resp == num;
        }) > -1;
      }
      
      return num == response;
    }
  },
  addChecked (ev) {
    let $lable, $input, $fields, name, value, strings;
    
    $input = $(ev.currentTarget);
    $label = $input.closest('label');
    name = $input.attr('name');
    $fields = this.$(`:checkbox[name=${name}]`);
    $checked = this.$(`:checkbox:checked[name=${name}]`);
    
    values = $checked.get().map(el => {
      return $(el).val();
    });
    
    if ($input.is(':checked')) {
      $label.addClass('checked');
    } else {
      $label.removeClass('checked');
    }
    
    if ($fields.length > 1) {
      this.store.responses.set(
        this.screenName,
        name,
        values
      );
    } else {
      this.store.responses.set(
        this.screenName,
        name,
        $input.is(':checked')
      );
    }
  }
});
