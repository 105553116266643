module.exports = {
  screen: 'A7',
  section: 'A',
  view: 'screen-likert',
  screenKeys: [
    'question.1',
    'option.2',
    'option.3',
    'option.4'
  ]
}
