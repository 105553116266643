const { isUndefined } = require('underscore');

module.exports = {
  store: require('store'),
  defaults: {
    language: 'en',
    studyId: false,
    furthest: 0,
    summaryReached: false,
    printed: false,
    failedSave: false
  },
  
  /**
   * Sets a response for a particular screen.
   *
   * @param {String}   key    the key of the response
   * @param {Whatever} value  the value to record
   */
  set (key, value) {
    let meta;
    
    if (!this.store.get('meta')) {
      this.store.set(
        'meta',
        Object.assign({}, this.defaults)
      );
    }
    
    meta = this.store.get('meta');
    meta[key] = value;
    
    this.store.set('meta', meta);
  },
  
  /**
   * Given a this will return the meta data assocaited
   * with that key.
   *
   * @param  {String} key [description]
   */
  get (key) {
    if (!this.store.get('meta')) {
      this.store.set(
        'meta',
        Object.assign({}, this.defaults)
      );
    }
    
    return this.store.get('meta')[key];
  },
  
  /**
   * Get all metadata.
   *
   * @return {Object} ALL the metadata
   */
  all () {
    return this.store.get('meta');
  },
  
  /**
   * Get a JSON representation of this store.
   *
   * @return {String} JSON representation of this store
   */
  json () {
    return JSON.stringify(
      this.store.get('meta')
    );
  },
  
  /**
   * Resets the meta data
   */
  reset () {
    // if we have a password, keep it
    let password = this.store.get('meta').password;
    
    this.store.set(
      'meta',
      Object.assign({
        password: password
      }, this.defaults)
    );
  }
}
