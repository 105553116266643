module.exports = {
  screen: 'E3',
  section: 'E',
  view: 'screen-radio',
  screenKeys: [
    'title.1',
    'question.2',
    'option.3',
    'option.4',
    'option.5',
    'footnote.6'
  ]
};
