const methods = require('@i18n/shared/methods.json');
const translations = require('@i18n/main.json');

const E5 = Object.assign({}, translations.E5);

E5.en.methods = Object.assign({}, methods.en);
E5.es.methods = Object.assign({}, methods.es);

E5.en.anotherMethod = methods.en[methods.en.length - 2].name;
E5.en.notInterested = methods.en[methods.en.length - 1].name;

E5.es.anotherMethod = methods.es[methods.es.length - 2].name;
E5.es.notInterested = methods.es[methods.es.length - 1].name;


module.exports = E5;
