const { isUndefined } = require('underscore');
const { youToldUs, canHelp } = require('@i18n/shared/recaps.json');

module.exports = {
  screen: 'A8',
  section: 'A',
  view: 'screen-divided-text',
  screenKeys: [
    'heading-1.1',
    'body.1.2',
    'heading-2.3',
    'body.2.4'
  ],
  preConditions: {
    'body.1.2': function (store, translations, language) {
      let { answer: A5 } = store.responses.get('A5');
      let { answer: A6 } = store.responses.get('A6');
      let { answer: A7 } = store.responses.get('A7');
      
      return `${youToldUs.sentenceOne[A5][A6][language]} ${youToldUs.sentenceTwo[A7][language]}`;
    },
    'body.2.4': function (store, translations, language) {
      let { answer: A6 } = store.responses.get('A6');
      let { answer: A7 } = store.responses.get('A7');
      
      if (!isUndefined(canHelp[A6]) &&
          !isUndefined(canHelp[A6][A7])) {
        return canHelp[A6][A7][language];
      } else {
        return canHelp.allElse[language];
      }
    }
  }
};
