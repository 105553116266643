var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (body, left, title, top) {pug_html = pug_html + "\u003Cdiv" + (" class=\"popover\""+pug.attr("style", pug.style(`top:${top}px;left:${left}px`), true, true)) + "\u003E\u003Cbutton class=\"closeButton js-close\"\u003E&times;\u003C\u002Fbutton\u003E";
if (title) {
pug_html = pug_html + "\u003Ch4 class=\"popoverTitle\"\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E";
}
if (body) {
pug_html = pug_html + "\u003Cp class=\"popoverBody\"\u003E" + (null == (pug_interp = body) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"body" in locals_for_with?locals_for_with.body:typeof body!=="undefined"?body:undefined,"left" in locals_for_with?locals_for_with.left:typeof left!=="undefined"?left:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"top" in locals_for_with?locals_for_with.top:typeof top!=="undefined"?top:undefined));;return pug_html;};
module.exports = template;