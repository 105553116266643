const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-reset.css');

module.exports = ScreenBase.extend({
  screenType: 'screen-reset',
  template: require('@templates/ScreenReset.pug'),
  events: {
    'click .js-tryToSave': 'tryToSave',
    'click .js-next': 'next'
  },
  render (success) {
    this.setElement(this.template({
      failedSave: this.store.meta.get('failedSave')
    }));
    
    this.$el.appendTo(
      this.$parent
    );
  },
  next () {
    this.router.goTo(0);
  },
  tryToSave () {
    this.router.goTo(-2);
  }
})
