var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (footnote, label, question, responses, title) {function isChecked (row, idx) { return responses[`answer.${row}`] == idx }
pug_mixins["radioOption"] = pug_interp = function(lbl, cls, row, idx){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["radio",cls,(isChecked(row, idx) ? 'selected' : '')], [false,true,true]), false, true)) + "\u003E\u003Cdiv class=\"option\"\u003E" + (null == (pug_interp = lbl) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"groupcopy2 radioOuter\"\u003E\u003Cimg class=\"stroke9copy\" src=\"\u002Fimg\u002Fmultiple-likert-other-outline.png\"\u003E\u003C\u002Fdiv\u003E\u003Cimg class=\"stroke9copy8 radioInner\" src=\"\u002Fimg\u002Fmultiple-likert-other-filled.png\"\u003E\u003Cinput" + (" type=\"radio\""+pug.attr("name", `answer.${row}`, true, true)+pug.attr("value", idx, true, true)+pug.attr("checked", isChecked(row, idx), true, true)) + "\u003E\u003C\u002Flabel\u003E";
};
pug_mixins["radioBank"] = pug_interp = function(row){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"radioscopy3 radioBank js-radioGroup\"\u003E";
// iterate ['yes', 'no', 'unsure']
;(function(){
  var $$obj = ['yes', 'no', 'unsure'];
  if ('number' == typeof $$obj.length) {
      for (var idx = 0, $$l = $$obj.length; idx < $$l; idx++) {
        var cls = $$obj[idx];
pug_mixins["radioOption"](label[idx], cls, row, idx);
      }
  } else {
    var $$l = 0;
    for (var idx in $$obj) {
      $$l++;
      var cls = $$obj[idx];
pug_mixins["radioOption"](label[idx], cls, row, idx);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"screenMultipleLikertOther\"\u003E\u003Cdiv class=\"title\"\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"questions\"\u003E\u003Cdiv class=\"radiorow3 radioRow\"\u003E\u003Cimg class=\"rule1\" src=\"\u002Fimg\u002Fmultiple-likert-other-rule.png\"\u003E\u003Cdiv class=\"question anima-valign-text-middle\"\u003E \u003Cspan\u003E" + (null == (pug_interp = question[0]) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
pug_mixins["radioBank"](1);
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"radiorow2 radioRow\"\u003E\u003Cimg class=\"rule1\" src=\"\u002Fimg\u002Fmultiple-likert-other-rule.png\"\u003E\u003Cdiv class=\"question anima-valign-text-middle\"\u003E \u003Cspan\u003E" + (null == (pug_interp = question[1]) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
pug_mixins["radioBank"](2);
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"radiorow1 radioRow\"\u003E\u003Cimg class=\"rule1\" src=\"\u002Fimg\u002Fmultiple-likert-other-rule.png\"\u003E\u003Cdiv class=\"question anima-valign-text-middle\"\u003E \u003Cspan\u003E" + (null == (pug_interp = question[2]) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
pug_mixins["radioBank"](3);
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"radiorow radioRow\"\u003E\u003Cimg class=\"rule1\" src=\"\u002Fimg\u002Fmultiple-likert-other-rule.png\"\u003E\u003Cdiv class=\"question anima-valign-text-middle\"\u003E \u003Cspan\u003E" + (null == (pug_interp = question[3]) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
pug_mixins["radioBank"](4);
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cimg class=\"rule\" src=\"\u002Fimg\u002Fmultiple-likert-other-rule.png\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"footnote\"\u003E" + (null == (pug_interp = footnote) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"footnote" in locals_for_with?locals_for_with.footnote:typeof footnote!=="undefined"?footnote:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"question" in locals_for_with?locals_for_with.question:typeof question!=="undefined"?question:undefined,"responses" in locals_for_with?locals_for_with.responses:typeof responses!=="undefined"?responses:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;