const IGNORE_SCREENS = [
  'B18',
  'FAQ'
];

/**
 * Determines if the supplied content key should be ignored
 * for term highlighting.
 *
 * This thing is pretty primitive, so it may need some
 * adjusting here and there... also, i am not above just
 * hardcoding some shit in here :)
 *
 * @param  {String} name content key to check
 * @return {Boolean}     true if the content associated
 * with the supplied name should be ignored, false
 * otherwise
 */
module.exports = function (name, conf) {
  return (
    name.toLowerCase().indexOf('column-title') > -1 ||
    name.toLowerCase().indexOf('tab') > -1 ||
    IGNORE_SCREENS.indexOf(conf.screen) > -1
  );
}
