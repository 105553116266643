const { bind } = require('underscore');
const ScreenBase = require('./ScreenBase');

require('@assets/css/screen-video.css');

module.exports = ScreenBase.extend({
  noFeedback: true,
  template: require('@templates/ScreenVideo.pug'),
  screenType: 'screen-video',
  events: {
    'click .js-replayBtn': 'replayVideo',
    'click .js-stopBtn': 'stopVideo',
    'click .js-playBtn': 'playVideo',
    'click .js-pauseBtn': 'pauseVideo',
    'click .js-muteBtn': 'muteVideo',
    'click .js-unMuteBtn': 'unMuteVideo'
  },
  postRender () {
    this.videoIdx = 0;
    this.$progress = this.$('.js-videoProgress');
    this.$replay = this.$('.js-replayBtn');
    this.$play = this.$('.js-playBtn');
    this.$pause = this.$('.js-pauseBtn');
    this.$mute = this.$('.js-muteBtn');
    this.$unmute = this.$('.js-unMuteBtn');

    this.setupVideo();
  },
  enableNextMaybe () {
    let { watched } = this.store.responses.get(
      this.screenName
    );

    if (watched) {
      this.systemEvents.trigger('next:enable');
    }
  },
  setupVideo () {
    this.$progress.attr({
      value: 0
    });
    this.$replay.addClass('g-hidden');

    // register the progress update listener
    this.$video.on('timeupdate',
      bind(this.updateProgress, this)
    ).show();

    // set the new video source
    if (!this.$video.data('videostarted')) {
      this.$source.attr({
        src: this.videoSource
      });
      
      this.$video.on('canplay', () => {
        this.video.play().catch(err => {
          // if we fail to play, swap the pause
          // for the play button, so the video
          // can atleast be played...
          this.$play.removeClass('g-hidden');
          this.$pause.addClass('g-hidden');
        });
        
        this.prepControls();
      });
      
      // start playback
      this.video.load();
    } else {
      this.$video.data('videostarted', false);
      this.prepControls();
    }
  },
  prepControls () {
    this.$pause.removeClass('g-hidden');
    
    if (this.$video.prop('muted')) {
      this.$mute.addClass('g-hidden');
      this.$unmute.removeClass('g-hidden');
    } else {
      this.$mute.removeClass('g-hidden');
      this.$unmute.addClass('g-hidden');
    }
  },
  playVideo () {
    if (this.video.paused) {
      this.video.play();
      this.$play.addClass('g-hidden');
      this.$pause.removeClass('g-hidden');
    }
  },
  pauseVideo () {
    if (!this.video.paused) {
      this.video.pause();
      this.$play.removeClass('g-hidden');
      this.$pause.addClass('g-hidden');
    }
  },
  replayVideo () {
    this.video.currentTime = 0;
    this.video.play();
    this.$replay.addClass('g-hidden');
    this.$pause.removeClass('g-hidden');
    this.$progress.removeClass('g-hidden');
    this.$play.addClass('g-hidden');
    
    if (this.$video.prop('muted')) {
      this.$mute.addClass('g-hidden');
      this.$unmute.removeClass('g-hidden');
    } else {
      this.$mute.removeClass('g-hidden');
      this.$unmute.addClass('g-hidden');
    }
  },
  muteVideo () {
    this.$video.prop('muted', true);
    this.$mute.addClass('g-hidden');
    this.$unmute.removeClass('g-hidden');
  },
  unMuteVideo () {
    this.$video.prop('muted', false);
    this.$mute.removeClass('g-hidden');
    this.$unmute.addClass('g-hidden');
  },
  updateProgress () {
    let currentProgress = (
      this.video.currentTime /
      this.video.duration
    ) * 100;

    this.$progress.attr({
      value: currentProgress
    });

    if (currentProgress === 100) {
      this.$replay.removeClass('g-hidden');
      this.$play.addClass('g-hidden');
      this.$pause.addClass('g-hidden');
      this.$progress.addClass('g-hidden');
      this.$mute.addClass('g-hidden');
      this.$unmute.addClass('g-hidden');
      this.store.responses.set(
        this.screenName,
        'watched',
        true
      );
      this.systemEvents.trigger('next:enable');
    }
  },
  unload () {
    this.video.pause();
    this.$source.attr({
      src: ''
    });
    this.$video.hide();
    this.$video.off('timeupdate');
    this.$video.off('canplay');
    
  }
});
