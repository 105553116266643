const $ = require('jquery');
const { isUndefined } = require('underscore');
const { Nav1 } = require('@i18n');
const Base = require('./Base');
const ScreenFaq = require('./ScreenFaq');
const progress = require('../util/progress');
const sectionStart = require('../util/sectionStart');
const processScreen = require('../util/processScreen');
const faqConf = require('../screens/configs/FAQ');

const FAQ = 'FAQ';
const SECTION_WIDTH = (1024 - 116) / 5;
const $body = $('body');

require('@assets/css/navigation.css');

module.exports = Base.extend({
  template: require('../templates/Navigation.pug'),
  events: {
    'click .navItem': 'goToSection'
  },
  initialize (opts) {
    let language = this.store.meta.get('language');
    
    this.$parent = opts.$parent;
    this.router = opts.router;
    
    this.systemEvents.on({
      'screen:change': this.updateProgress,
      'navigation:hide': this.hide,
      'language:change': this.render,
      'link:faq': this.launchFAQ
    }, this);
    
    this.render();
  },
  render () {
    let language = this.store.meta.get('language');
    
    if (this.$el) {
      this.$el.remove();
      delete this.$el;
    }
    
    this.setElement(
      this.template({
        labels: Nav1[language]
      })
    );
    
    this.$el.appendTo(
      this.$parent
    );
      
    this.sections = {
      A: this.$('.js-sectionA'),
      B: this.$('.js-sectionB'),
      C: this.$('.js-sectionC'),
      D: this.$('.js-sectionD'),
      E: this.$('.js-sectionE')
    };
    
    this.completionPercentage = this.$('.js-completionPercentage');
    
    if (this.faq) {
      this.faq.destroy();
    }
    
    this.faq = new ScreenFaq(
      processScreen(
        faqConf,
        language,
        59,
        $body,
        this.router,
        this.store
      )
    );
    
    this.faq.render();
  },
  updateProgress (idx) {
    let {currentSection, perScreen } = progress(idx);
    let furthest = this.store.meta.get('furthest');
    
    this.$('.currentSection').removeClass('currentSection');
    this.sections[currentSection].addClass('currentSection');
    
    Object.keys(this.sections).forEach(section => {
      let start = sectionStart(section);
      
      if (start <= furthest) {
        this.sections[section].removeClass('disabled');
      } else {
        this.sections[section].addClass('disabled');
      }
    })
    
    let totalWidth = 0;
    Object.keys(perScreen).forEach(key => {
      let { seen, total } = perScreen[key];
      
      if (!isUndefined(this.sections[key])) {
        if (key === 'E') {
          totalWidth +=  (SECTION_WIDTH * (seen / (total - 1)));
        } else {
          totalWidth +=  (SECTION_WIDTH * (seen / total));
        }
      }
    });
    
    this.completionPercentage.css({
      width: `${Math.round(totalWidth)}px`
    });
  },
  launchFAQ (sectionIdx, questionIdx) {
    if (!isUndefined(questionIdx)) {
      this.faq.show(`section${sectionIdx}-${questionIdx}`);
    } else {
      this.faq.show(`section${sectionIdx}`);
    }
  },
  goToSection (ev) {
    let $el = $(ev.currentTarget);
    let section = $el.data('section');
    
    if (section === FAQ) {
      this.faq.show();
    } else if ($el.hasClass('disabled')) {
      ev.preventDefault();
      ev.stopPropagation();
      return;
    } else {
      this.router.goTo(
        sectionStart(section)
      );
    }
  }
});
