var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Object, helpers, language, sections, title) {let conf = {}, count = {};
pug_mixins["faqingSectionGroup"] = pug_interp = function(first, sectionIdx, questionIdx, { question, answers }){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (" class=\"screenFaq-section\""+pug.attr("id", `section${sectionIdx}-${questionIdx}`, true, true)) + "\u003E\u003Ch4 class=\"screenFaq-question\"\u003E" + (null == (pug_interp = helpers.processText(language, question, conf, count, true)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003Cdiv class=\"screenFaq-answer\"\u003E";
// iterate answers
;(function(){
  var $$obj = answers;
  if ('number' == typeof $$obj.length) {
      for (var answerIdx = 0, $$l = $$obj.length; answerIdx < $$l; answerIdx++) {
        var answer = $$obj[answerIdx];
pug_html = pug_html + "\u003Cp\u003E" + (null == (pug_interp = helpers.processText(language, answer, conf, count, true)) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
      }
  } else {
    var $$l = 0;
    for (var answerIdx in $$obj) {
      $$l++;
      var answer = $$obj[answerIdx];
pug_html = pug_html + "\u003Cp\u003E" + (null == (pug_interp = helpers.processText(language, answer, conf, count, true)) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"js-modalContent\"\u003E";
let headings = Object.keys(sections)
pug_html = pug_html + "\u003Cdiv class=\"screenFaq-sidebar\"\u003E\u003Cnav class=\"screenFaq-nav\"\u003E\u003Cul class=\"screenFaq-navList\"\u003E";
// iterate headings
;(function(){
  var $$obj = headings;
  if ('number' == typeof $$obj.length) {
      for (var idx = 0, $$l = $$obj.length; idx < $$l; idx++) {
        var heading = $$obj[idx];
pug_html = pug_html + "\u003Cli class=\"screenFaq-item\"\u003E\u003Ca" + (" class=\"js-scrollTo\""+pug.attr("data-section", `section${idx}`, true, true)) + "\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var idx in $$obj) {
      $$l++;
      var heading = $$obj[idx];
pug_html = pug_html + "\u003Cli class=\"screenFaq-item\"\u003E\u003Ca" + (" class=\"js-scrollTo\""+pug.attr("data-section", `section${idx}`, true, true)) + "\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fnav\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"screenFaq close js-close\"\u003E\u003Cdiv class=\"group\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"screenFaq\"\u003E\u003Cdiv class=\"screenFaq-wrap\"\u003E\u003Cdiv class=\"screenFaq-header\"\u003E\u003Ch2\u003E" + (null == (pug_interp = title) ? "" : pug_interp) + "\u003C\u002Fh2\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"screenFaq-content\"\u003E\u003Cdiv class=\"screenFaq-tableOfContents\"\u003E\u003Cnav\u003E\u003Cul\u003E";
// iterate headings
;(function(){
  var $$obj = headings;
  if ('number' == typeof $$obj.length) {
      for (var idx = 0, $$l = $$obj.length; idx < $$l; idx++) {
        var heading = $$obj[idx];
pug_html = pug_html + "\u003Cli class=\"screenFaq-item\"\u003E\u003Ca" + (" class=\"js-scrollTo\""+pug.attr("data-section", `section${idx}`, true, true)) + "\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003Cul\u003E";
// iterate sections[heading]
;(function(){
  var $$obj = sections[heading];
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var subGroup = $$obj[i];
pug_html = pug_html + "\u003Cli class=\"screenFaq-item-question\"\u003E\u003Ca" + (" class=\"js-scrollTo\""+pug.attr("data-section", `section${idx}-${i}`, true, true)) + "\u003E" + (null == (pug_interp = subGroup.question) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var subGroup = $$obj[i];
pug_html = pug_html + "\u003Cli class=\"screenFaq-item-question\"\u003E\u003Ca" + (" class=\"js-scrollTo\""+pug.attr("data-section", `section${idx}-${i}`, true, true)) + "\u003E" + (null == (pug_interp = subGroup.question) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var idx in $$obj) {
      $$l++;
      var heading = $$obj[idx];
pug_html = pug_html + "\u003Cli class=\"screenFaq-item\"\u003E\u003Ca" + (" class=\"js-scrollTo\""+pug.attr("data-section", `section${idx}`, true, true)) + "\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003Cul\u003E";
// iterate sections[heading]
;(function(){
  var $$obj = sections[heading];
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var subGroup = $$obj[i];
pug_html = pug_html + "\u003Cli class=\"screenFaq-item-question\"\u003E\u003Ca" + (" class=\"js-scrollTo\""+pug.attr("data-section", `section${idx}-${i}`, true, true)) + "\u003E" + (null == (pug_interp = subGroup.question) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var subGroup = $$obj[i];
pug_html = pug_html + "\u003Cli class=\"screenFaq-item-question\"\u003E\u003Ca" + (" class=\"js-scrollTo\""+pug.attr("data-section", `section${idx}-${i}`, true, true)) + "\u003E" + (null == (pug_interp = subGroup.question) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fnav\u003E\u003C\u002Fdiv\u003E";
// iterate headings
;(function(){
  var $$obj = headings;
  if ('number' == typeof $$obj.length) {
      for (var idx = 0, $$l = $$obj.length; idx < $$l; idx++) {
        var heading = $$obj[idx];
let group = sections[heading];
pug_html = pug_html + "\u003Cdiv class=\"screenFaq-sectionWrapper\"\u003E";
// iterate group
;(function(){
  var $$obj = group;
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var subGroup = $$obj[i];
if (!i) {
pug_html = pug_html + "\u003Ch3" + (pug.attr("id", `section${idx}`, true, true)) + "\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
}
else {
pug_html = pug_html + "\u003Ch3\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
}
pug_mixins["faqingSectionGroup"](!i, idx, i, subGroup);
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var subGroup = $$obj[i];
if (!i) {
pug_html = pug_html + "\u003Ch3" + (pug.attr("id", `section${idx}`, true, true)) + "\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
}
else {
pug_html = pug_html + "\u003Ch3\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
}
pug_mixins["faqingSectionGroup"](!i, idx, i, subGroup);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var idx in $$obj) {
      $$l++;
      var heading = $$obj[idx];
let group = sections[heading];
pug_html = pug_html + "\u003Cdiv class=\"screenFaq-sectionWrapper\"\u003E";
// iterate group
;(function(){
  var $$obj = group;
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var subGroup = $$obj[i];
if (!i) {
pug_html = pug_html + "\u003Ch3" + (pug.attr("id", `section${idx}`, true, true)) + "\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
}
else {
pug_html = pug_html + "\u003Ch3\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
}
pug_mixins["faqingSectionGroup"](!i, idx, i, subGroup);
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var subGroup = $$obj[i];
if (!i) {
pug_html = pug_html + "\u003Ch3" + (pug.attr("id", `section${idx}`, true, true)) + "\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
}
else {
pug_html = pug_html + "\u003Ch3\u003E" + (null == (pug_interp = heading) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
}
pug_mixins["faqingSectionGroup"](!i, idx, i, subGroup);
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"Object" in locals_for_with?locals_for_with.Object:typeof Object!=="undefined"?Object:undefined,"helpers" in locals_for_with?locals_for_with.helpers:typeof helpers!=="undefined"?helpers:undefined,"language" in locals_for_with?locals_for_with.language:typeof language!=="undefined"?language:undefined,"sections" in locals_for_with?locals_for_with.sections:typeof sections!=="undefined"?sections:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;