const $ = require('jquery');
const { flatten } = require('underscore');
const ScreenBaseModal = require('./ScreenBaseModal');
const comparisons = require('@i18n/shared/comparisons.json');

require('@assets/css/screen-comparison-table.css');

module.exports = ScreenBaseModal.extend({
  template: require('@templates/ScreenComparisonTable.pug'),
  screenType: 'screen-comparison-table',
  events: {
    'click tr': 'toggleRow',
    'click .js-closeModal': 'hide',
    'scroll': 'watchHeader'
  },
  render (methods = []) {
    let language = this.store.meta.get('language');

    if (this.$el) {
      this.$el.remove();
      delete this.$el;
    }

    this.setElement(
      this.template({
        screenIndex: this.screenIndex,
        screenType: this.screenType,
        screenName: this.screenName,
        comparisons: flatten(methods.map(method => {
          return comparisons[language][method]
        })),
        ...this.templateOptions
      })
    );

    this.$el.hide().appendTo(
      this.$parent
    );
  },
  toggleRow (ev) {
    let $el = $(ev.currentTarget);
    const STATE = 'open';

    if ($el.hasClass(STATE)) {
      $el.removeClass(STATE);
    } else {
      $el.addClass(STATE);
    }
  },
  postShow() {
    setTimeout(() => {
      // Loop over all the <td>'s and compare the heights of the child
      // and grandchild <div>'s. If the grandchild is larger add a class
      this.$el.find('td').each((i, td) => {
        let $td = $(td);
        let inner = $td.find('.inner').outerHeight();
        let outer = $td.find('.outer').outerHeight();

        if (inner > outer) {
          $td.addClass('collapsed');
        }
      });
    }, 0);
  },
  watchHeader () {
    let $wrap = this.$el.find('.screenComparisonTable-wrap');
    let $header = this.$el.find('.screenComparisonTable-table');
    let $closeBtn = this.$el.find('.js-closeModal');

    if (-$wrap.position().top > 0) {
      $header.addClass('fixed');
      $closeBtn.addClass('fixed');
    } else {
      $header.removeClass('fixed');
      $closeBtn.removeClass('fixed');
    }
  }
});
