module.exports = {
  screen: 'C2',
  section: 'C',
  view: 'screen-multiple-likert',
  screenKeys: [
    'title.1',
    'body.2',
    'label.3',
    'label.4',
    'label.5',
    'label.6',
    'question.7',
    'question.8',
    'question.9',
    'question.10'
  ]
};
