const { isFunction } = require('underscore');

/**
 * Determines if the supplied config has dynamic content.
 *
 * @param  {Object} conf configuration object to check
 * @return {Boolean}     true if the supplied conf has dyanmic
 * content, false otherwise
 */
module.exports = function (conf) {
  return isFunction(conf.dynamicContent);
}
