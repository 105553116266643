const YES = 0;

module.exports = {
  screen: 'D3',
  section: 'D',
  view: 'screen-checkboxes',
  screenKeys: [
    'title',
    'body',
    'button',
    'sections'
  ],
  modals: [
    require('./others')
  ],
  conditionalShow (store) {
    let { answer } = store.responses.get('D2');
    
    return answer == YES;
  }
};
