const $ = require('jquery');
const ScreenBase = require('./ScreenBase');
const checkPassword = require('../util/checkPassword');
const store = require('../store');

require('select2');

require('select2/dist/css/select2.css');
require('@assets/css/screen-study-id.css');

const STUDY_ID_KEY = 'studyId';
const PASSWORD_KEY = 'password';
const DEMO_ID = 'demo';

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenStudyId.pug'),
  screenType: 'screen-study-id',
  events: {
    'keyup input': 'enableNextBtn',
    'change .js-studyId': 'recordStudyid',
    'change .js-password': 'recordPassword',
    'click .js-preview': 'doPreview',
    'click .js-participant': 'doParticipant',
    'click .js-next': 'nextScreen'
  },
  templateHelpers: {
    getPassword () {
      return store.meta.get('password');
    }
  },
  postRender () {
    this.$('.js-studyId').select2({
      ajax: {
        url: '/api/ids.php',
        dataType: 'json',
        processResults: data => ({ results: data })
      }
    });
  },
  recordStudyid (ev) {
    // store the study id
    this.store.meta.set(
      STUDY_ID_KEY,
      $(ev.currentTarget).val()
    );
    
    this.enableNextBtn();
  },
  recordPassword (ev) {
    // store the password
    this.store.meta.set(
      PASSWORD_KEY,
      $(ev.currentTarget).val()
    );
    
    this.enableNextBtn();
  },
  enableNextBtn () {
    let $select = this.$('.js-studyId');
    let $password = this.$('.js-password');
    let $next = this.$('.js-next');
    let studyId = $select.val() ? $select.val().trim().length : false;
    let password = $password.val();
    
    if (studyId && checkPassword(password)) {
      $next.removeAttr('disabled');
    } else {
      $next.attr({
        'disabled': 'disabled'
      });
    }
  },
  doPreview () {
    // if we are doing a preview, just record
    // the demo id and move on
    this.store.meta.set(STUDY_ID_KEY, DEMO_ID);
    this.store.meta.set(PASSWORD_KEY, '');
    
    this.router.nextScreen();
  },
  doParticipant () {
    let $form = this.$('.js-participantForm');
    
    $form.removeClass('g-hidden');
    
  },
  nextScreen () {
    this.router.nextScreen();
  }
});
