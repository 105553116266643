const { chain } = require('underscore');
const ScreenBaseRadio = require('./ScreenBaseRadio');

require('@assets/css/screen-multiple-radio.css');

const YES = 0;
const NO = 1;

module.exports = ScreenBaseRadio.extend({
  template: require('@templates/ScreenMultipleRadio.pug'),
  screenType: 'screen-multiple-radio',
  postRender () {
    ScreenBaseRadio.prototype.postRender.apply(this, arguments);
    let fillOut = this.$(':radio:checked[name=fillOut]');
    
    if (fillOut.length) {
      this.store.responses.set(
        this.screenName,
        'fillOut',
        fillOut.val()
      );
    }
  },
  optionChangedPlus ($el, name, value) {
    if (name === 'fillOut') {
      if (value == YES) {
        this.$el.addClass('hideOverlay');
        this.enableNextMaybe();
      } else {
        this.$el.removeClass('hideOverlay');
        this.systemEvents.trigger('next:enable');
      }
    }
  },
  enableNextMaybe () {
    let total = chain(this.$(':radio').get())
                     .pluck('name')
                     .uniq()
                     .value()
                     .length;
    let checked = this.$(':radio:checked').length;
    let fillOut = this.$(':radio:checked[name=fillOut]').val();
    
    if (fillOut == NO) {
      return this.systemEvents.trigger('next:enable');
    }
    
    // if we have a non-zero checked count
    // and that count is equal to the total
    // number of unique radio groups
    if (checked && (checked === total)) {
      this.systemEvents.trigger('next:enable');
    } else {
      this.systemEvents.trigger('next:disable');
    }
  },
});
