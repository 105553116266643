module.exports = {
  screen: 'E6',
  section: 'E',
  view: 'screen-text-over-checkboxes',
  screenKeys: [
    'title.1',
    'section.2',
    'body.3',
    'option.4',
    'option.5',
    'option.6',
    'option.7',
    'hint-text.8'
  ]
};
