const main = require('@i18n/main.json');

// if we have any special screens, handle them here...

main.D3 = require('@i18n/overrides/D3.js');
main.E5 = require('@i18n/overrides/E5.js');
main.E7 = require('@i18n/overrides/E7.js');
main.E8 = require('@i18n/summary-report.json');
main.FAQ = require('@i18n/faq.json');

module.exports = main;
