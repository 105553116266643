const { isUndefined } = require('underscore');

module.exports = {
  store: require('store'),
  
  /**
   * Sets a response for a particular screen.
   *
   * @param {String}   screen id of the screen to to record this response for
   * @param {String}   key    the key of the response
   * @param {Whatever} value  the value to record
   */
  set (screen, key, value) {
    let responses;
    
    if (!this.store.get('responses')) {
      this.store.set('responses', {});
    }
    
    responses = this.store.get('responses');
    
    if (isUndefined(responses[screen])) {
      responses[screen] = {};
    }
    
    responses[screen][key] = value;
    
    this.store.set('responses', responses);
  },
  
  /**
   * Get a JSON representation of this store.
   *
   * @return {String} JSON representation of this store
   */
  json () {
    return JSON.stringify(
      this.store.get('responses')
    );
  },
  
  /**
   * Grabs the responses for a particular screen.
   *
   * @param  {String} screen screen to grab responses for
   * @return {Array}         an array of the responses for the screen
   */
  get (screen) {
    let responses;
    
    if (!this.store.get('responses')) {
      this.store.set('responses', {});
    }
    
    responses = this.store.get('responses');
    
    return Object.assign(
      {},
      responses[screen]
    );
  },
  
  /**
   * Returns ALL response data.
   *
   * @return {Object} 
   */
  all () {
    return this.store.get('responses');
  },
  
  /**
   * Resets the responses.
   */
  reset () {
    this.store.set('responses', {});
  }
}
