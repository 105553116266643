const processText = require('./processText');
const hasPreconditions = require('./hasPrecondition');
const hasDynamicContent = require('./hasDynamicContent');
const ignoreHighlight = require('./ignoreHighlight');

/**
 * Given a current configuration and the current language, this
 * function will prepare the parameters for use in the templates.
 *
 * @param  {Object} conf         configuration of the current screen
 * @param  {Object} translations translations for all the current screen
 * @param  {String} language currently selected language
 * @param  {Object} store a reference to the data store
 * @return {Object}      the templateOptions to pass along to the template
 */
module.exports = (conf, translations, language, store) => {
  let counts = {};
  let translationKey = (conf.translationKey
    ? conf.translationKey
    : conf.screen
  );
  let groups = conf.screenKeys.map((key) => {
    let tokens = key.split(/\./);
    let content = (hasPreconditions(conf, key)
      ? conf.preConditions[key](
          store,
          translations,
          language
        )
      : translations[translationKey][language][key]
    );
    
    return {
      content: processText(
        language,
        content,
        conf,
        counts,
        ignoreHighlight(tokens[0], conf)
      ),
      group: tokens[0] + (tokens.length > 2 ? `${tokens[1]}` : ''),
      screenIdx: +tokens[tokens.length - 1]
    };
  }).reduce((opts, item) => {
    if (!opts[item.group]) {
      opts[item.group] = [];
    }
    
    opts[item.group].push({
      content: item.content,
      screenIdx: item.screenIdx
    });
    
    return opts;
  }, {});
  
  let dynamicContent = (hasDynamicContent(conf)
    ? conf.dynamicContent(store)
    : {}
  );
  
  return Object.keys(groups).reduce((h, k) => {
    let sorted = groups[k].sort((a, b) => {
      return a.screenIdx - b.screenIdx;
    }).map(v => v.content);
    
    h[k] = (sorted.length > 1
      ? sorted
      : sorted[0]
    );
    
    return h;
  }, {
    classes: (conf.classes || []),
    ...dynamicContent
  });
}
