module.exports = {
  screen: 'E5',
  section: 'E',
  view: 'screen-checkbox-list',
  screenKeys: [
    'title.1',
    'body.2',
    'methods',
    'notInterested',
    'anotherMethod'
  ]
};
