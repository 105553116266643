const axios = require('axios');
const store = require('../store');
const checkPassword = require('../util/checkPassword');

/**
 * Saves the current participant's response data.
 *
 * @return {Promise} a promise for the response data save request
 */
module.exports = function () {
  return axios.post('/api/save/responses.php', {
    studyId: store.meta.get('studyId'),
    authenticated: checkPassword(store.meta.get('password')),
    language: store.meta.get('language'),
    value: store.responses.all()
  }).then((resp) => {
    // if response code is not 2xx, then something bad happened
    if (Math.floor(resp.status / 100) !== 2) {
      throw `Responses save request returned status code ${xhr.status}`;
    }
    
    return true;
  }).catch((error) => {
    console.error('Responses save request failed.');
    
    return false;
  });
}
