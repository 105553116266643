const _ = require('underscore');
const Dexie = require('dexie').default;
const async = require('async');
const checkPassword = require('../util/checkPassword');

const db = new Dexie('my-decision-backups');

// initialize our database store
db.version(1).stores({
  backups: '++id, studyId'
});


/**
 * Provides an interface into our local store.
 *
 * @type {Object} a reference to our store
 */
module.exports = {
  meta: require('./collections/Meta'),
  notes: require('./collections/Notes'),
  topics: require('./collections/Topics'),
  responses: require('./collections/Responses'),
  feedback: require('./collections/Feedback'),
  
  /**
   * Returns a JSON dump of the store.
   *
   * @return {String} a JSON string of the store
   */
  json () {
    return JSON.stringify({
      meta: this.meta.json(),
      notes: this.notes.json(),
      topics: this.topics.json(),
      responses: this.responses.json(),
      feedback: this.feedback.json()
    })
  },
  
  /**
   * Returns a living breathing Object of the store.
   *
   * note: this is currently just for grabbing a copy of
   * store to save into the backup store
   *
   * @return {Object} an object containing entries
   * for each collection in the store
   */
  all () {
    let password = this.meta.get('password');
    
    // return everything, but make sure to block out
    // the password... we should probably avoid having
    // that saved in storage long term
    return {
      meta: {
        ...this.meta.all(),
        password: '-',
        authenticated: checkPassword(password)
      },
      notes: this.notes.all(),
      topics: this.topics.all(),
      responses: this.responses.all(),
      feedback: this.feedback.all()
    }
  },
  
  /**
   * Resets each collection within the store.
   */
  reset () {
    this.meta.reset();
    this.notes.reset();
    this.topics.reset();
    this.responses.reset();
    this.feedback.reset();
  },
  
  /**
   * Given a study ID and some data to be saved, this
   * will create a record in the backups table.
   *
   * This does not use local storage, it instead uses IndexedDB,
   * which provides substantially more space and a longer
   *
   * @param  {String}   studyId the study ID associated with this data
   * @param  {Object}   data    the actual data to store
   * @param  {Function} done    callback function. the first parameter will
   * be an error or falsy for no error.
   */
  backup (done) {
    db.open().then(() => {
      db.backups.put({
        studyId: this.meta.get('studyId'),
        date: (new Date()).toISOString(),
        data: this.all()
      }).then(() => done())
        .catch(done);
    }).catch(done);
  },

  /**
   * Get all entries for a specific studyId.
   *
   * @param  {String} studyId the study ID to retrieve entries for
   * @return {Array}         all entries registered against the
   * supplied study ID
   */
  getBackup (studyId, done) {
    db.open().then(() => {
      db.backups.where('studyId')
                .equals(studyId)
                .toArray(records => done(false, records || []))
                .catch(done);
    }).catch(done);
  },
  
  /**
   * Get all the datas.
   *
   * @return {Array} array of datas n'at
   */
  getAllBackups (done) {
    db.open().then(() => {
      db.backups.toArray(records => {
        done(false, records || [])
      }).catch(done);
    }).catch(done);
  }
};
