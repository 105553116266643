module.exports = {
  screen: 'B25',
  section: 'B',
  view: 'screen-one-two-column',
  screenKeys: [
    'title.1',
    'body.2',
    'sidebar.title.3',
    'sidebar.body.4'
  ]
};
