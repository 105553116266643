const $ = require('jquery');
const ScreenBase = require('./ScreenBase');
const ScreenTabbedModal = require('./ScreenTabbedModal');

require('@assets/css/screen-three-column.css');

module.exports = ScreenBase.extend({
  template: require('@templates/ScreenThreeColumn.pug'),
  screenType: 'screen-three-column',
  events: {
    'click .js-launchModal': 'launchModal'
  },
  launchModal (ev) {
    let $el = $(ev.currentTarget);
    let modal = $el.data('modal');
    let tab = $el.data('tab');
    
    if (this.modals[modal]) {
      this.modals[modal].show(tab);
    }
  }
});
