const $ = require('jquery');
const { isFunction } = require('underscore');
const ScreenBaseRadio = require('./ScreenBaseRadio');

require('@assets/css/screen-multiple-likert.css');

module.exports = ScreenBaseRadio.extend({
  template: require('@templates/ScreenMultipleLikert.pug'),
  screenType: 'screen-multiple-likert'
});
