const uuid = require('uuid');

/**
 * Notes store.
 *
 * @type {Object}
 */
module.exports = {
  store: require('store'),
  
  /**
   * Adds a note the note storage module.
   *
   * @param {String} text   the actual note text
   * @param {String} screen (optional) screen on which the
   * note was recorded
   *
   * a UUID for the note will be generated when inserted
   */
  add (text, screen) {
    let notes;
    
    if (!this.store.get('notes')) {
      this.store.set('notes', []);
    }
    
    notes = this.store.get('notes');
    
    notes.push({
      id: uuid(),
      text: text,
      screen: screen
    });
    
    this.store.set('notes', notes);
  },
  
  /**
   * Given a note, this will remove the note from the store.
   *
   * @param  {String} noteId id of the note to remove
   */
  remove (noteId) {
    let notes, idx;
    
    if (!this.store.get('notes')) {
      this.store.set('notes', []);
    }
    
    notes = this.store.get('notes');
    
    this.store.set('notes', notes.filter(note => {
      return note.id !== noteId;
    }));
  },
  
  /**
   * Returns a list of all available notes in the system.
   *
   * @return {Array} a listing of all notes in the system
   */
  all () {
    if (!this.store.get('notes')) {
      this.store.set('notes', []);
    }
    
    return this.store.get('notes');
  },
  
  /**
   * Retrieves notes for a particular screen.
   *
   * @param  {String} screen the screen to grab notes for
   * @return {Array}        an array of any notes that have
   * been associated with this screen
   */
  forScreen (screen) {
    if (!this.store.get('notes')) {
      this.store.set('notes', []);
    }
    
    return this.get('notes').filter(note =>
      note.screen === screen
    );
  },
  
  /**
   * Get a JSON representation of this store.
   *
   * @return {String} JSON representation of this store
   */
  json () {
    return JSON.stringify(
      this.store.get('notes')
    );
  },
  
  /**
   * Resets the notes to an empty state.
   */
  reset () {
    this.store.set('notes', []);
  }
}
